/*=====================
    27.Responsive CSS start
==========================*/

body {
  &.christmas {
    @media (min-width: 1630px) {
      .container {
        max-width: 1600px;
      }

    }

    @media (max-width: 1630px) {
      .home-slider {
        .slider-details {
          width: 400px;
          height: 400px;
        }
      }
    }
  }
}

@media (min-width: 1430px) {
  .container {
    max-width: 1400px;
  }

  .modal-dialog.popvn {
    min-width: 862px !important;
  }
}

@media (max-width: 767px) {
  .img_cont {
    width: 23px !important;
    height: 23px !important;
    object-fit: cover;
  }

  .brand-logo .logo {
    width: 128px !important;
  }

  .menu_icon {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  body .custome_heifht {
    padding-top: 73px !important;
  }

  body .cumdgciaaef {
    height: 68px !important;
  }

  .brand-logo img {
    height: 29px !important;
  }

  .signupamentor {
    background-color: var(--theme-deafult) !important;
  }

  .image_stories {
    height: 140px !important;
  }

  .bg_banner {
    .row {
      flex-direction: column-reverse !important;
    }
  }

  .bg_count {
    background-color: var(--theme-deafult) !important;
    background-image: none !important;
  }
}

@media (max-width: 1024px) {
  .empower_section {
    padding: 15px !important;
  }

  .bg_banner {
    background-color: #f4f9ef !important;
    padding: 20px 0px !important;

    .btn_banner {
      font-size: 12px !important;
      padding: 10px 19px !important;
    }

    .h2,
    h2 {
      font-size: 18px !important;
      font-weight: 700 !important;
      line-height: 25px;
    }

    .h5,
    h5 {
      font-size: 12px !important;
      line-height: 23px !important;
    }
  }
}

@media (max-width: 1430px) {

  header {
    &.left-header {
      .top-header {
        padding-left: 0;

        .header-contact {
          display: none;
        }
      }

      .sidenav {
        left: -300px;

        &.open-side {
          left: 0;
        }

        .sidebar-back {
          display: block;
          color: black;
          border-bottom: 1px dashed #dddddd;
        }

        .brand-logo {
          display: none;
        }

        .left-sidebar_center {
          padding: 0 0 25px 25px;
        }
      }

      .main-menu {
        .menu-left {
          .navbar {
            display: block;
          }

          .mobile-logo {
            display: block;
            border: none;
          }
        }
      }

      .search-overlay {
        padding-left: 0;
      }
    }
  }

  .left-sidebar_space {
    padding-left: 0;
  }

  .banner-slider .height-banner {
    height: unset;
  }

  .pet-parallax {
    .pet-decor {
      left: 60px;
      bottom: -125px;

      img {
        width: 240px;
      }
    }
  }

  .box-product {
    .theme-card {
      .offer-slider {
        .media {
          .media-body {
            .rating {
              i {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    .full-box {
      .theme-card {
        .offer-slider {
          .product-box2 {
            .media {
              .media-body {
                h4 {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  .blog-section {
    .review-box {
      .review-content {
        p {
          margin-bottom: 35px;
        }
      }
    }
  }

  // inner pages //
  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width: 100%;
          border-left: 1px solid #dddddd;
          border-bottom: 1px solid #dddddd;
          text-align: center;
        }

        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left: 1px solid #dddddd;
        }

        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;

          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }

        .product-page-filter {
          border-right: 1px solid #dddddd;
          width: 30%;
        }

        .product-page-per-view {
          width: 30%;
        }
      }

      .popup-filter {
        .collection-view {
          width: 10%;
        }

        .product-page-per-view {
          width: 25%;
        }
      }
    }
  }

  .cart-section {
    .cart-buttons {
      >div {
        &:last-child {
          padding-right: 38px;
        }
      }
    }

    tbody {
      tr {
        td {
          min-width: 175px;

          .qty-box {
            .input-group {
              .form-control {
                width: 75px;
              }
            }
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          padding-right: 41px;
        }
      }
    }
  }

  .product-right {
    .product-icon {
      .product-social {
        li {
          padding-right: 10px;
        }
      }
    }
  }

  .product-form-box {
    .timer {
      padding-left: 17px;

      span {
        width: 55px;
      }
    }
  }

  .tools_slider {
    .home-slider {
      .slider-contain {
        margin-left: 80px;
      }

      .home {

        .tools-parts,
        .tools-parts1 {
          img {
            width: 250px;
          }
        }

        .tools-parts {
          right: 0;
        }

        .tools-parts1 {
          right: 10%;
        }

        #tools-move2 {
          right: 7%;
        }
      }
    }
  }

  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            img {
              height: 220px;
            }
          }
        }
      }
    }
  }

  .full-box {
    .theme-card {
      .offer-slider {
        .product-box2 {
          .media {
            img {
              height: 250px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1430px) and (min-width: 1368px) {
  .banner-timer {
    .timer {
      margin-top: 45px;
    }
  }
}

@media (max-width: 1430px) and (min-width: 1200px) {
  header {
    .main-navbar {
      #mainnav {
        .nav-menu>li>a {
          padding-right: 36px;
        }
      }
    }
  }

  .layout3-menu {
    .absolute-logo {
      .brand-logo {
        margin-left: 80px;
      }
    }

    .main-menu {
      .menu-left {
        .navbar {
          padding: 40px 10px 40px 0;
        }
      }
    }

    .pixelstrap {
      >a {
        padding-right: 38px;
      }
    }
  }

  .portfolio-section {
    &.metro-section {
      .product-box {
        .cart-wrap {
          a {
            i {
              padding: 5px;
            }
          }

          i {
            font-size: 14px;
          }

          button {
            i {
              padding: 5px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1367px) {
  h2 {
    font-size: 32px;
  }

  .title1 {
    .title-inner1 {
      padding-bottom: 10px;

      &:after {
        height: 2px;
      }
    }
  }

  .title3 {
    .line {
      height: 3px;

      &:after,
      &:before {
        height: 3px;
      }
    }

    .title-inner3 {
      margin-bottom: 10px;
    }
  }

  .title4 {
    .title-inner4 {
      padding-bottom: 15px;
    }
  }

  hr {
    &.style1 {
      height: 1px;
      margin-top: 7px;
      margin-bottom: 7px;
    }
  }

  .home-slider {
    .slider-details {
      right: 14%;
    }

    &:hover {

      .slick-prev,
      .slick-next {
        transform: scale(1.8);
      }
    }
  }

  .product-box,
  .product-wrap {

    .product-detail,
    .product-info {
      .rating {
        i {
          padding-right: 3px;
        }
      }
    }
  }

  .blog-details {
    h4 {
      margin-top: 20px;
    }

    p {
      font-size: 16px;
    }
  }

  .service-block {
    h4 {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  .banner-timer {
    background-position: right;

    .banner-text {
      h2 {
        font-size: 28px;
      }
    }
  }

  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-five {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .subscribe-form {
    .form-control {
      width: 215px;
    }
  }

  .category-bg {
    .image-block {
      &.even {
        &:after {
          bottom: 91%;
        }
      }
    }

    .contain-block {
      h2 {
        font-size: 30px;
      }

      &.even {
        &:after {
          top: 91%;
        }
      }

      .category-btn {
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &:after {
        bottom: 91%;
      }
    }
  }

  .full-banner {
    .banner-contain {
      h2 {
        font-size: 90px;
      }

      h3 {
        font-size: 55px;
      }

      h4 {
        font-size: 24px;
        padding-top: 8px;
      }

      .color {
        padding-top: 0;
      }
    }
  }

  .collection-banner {
    .contain-banner {
      &.banner-3 {
        padding-left: 45px;
        padding-right: 45px;

        h2 {
          font-size: 30px;
        }
      }

      &.banner-4 {
        padding-left: 45px;
        padding-right: 45px;

        h2 {
          font-size: 28px;
        }
      }

      h2 {
        font-size: 55px;
        letter-spacing: 0.01em;
      }

      h4 {
        letter-spacing: 0.01em;
      }
    }

    &.christmas-banner {
      .contain-banner {
        >div {
          background-color: rgba(#000000, 0.2);
          padding: 25px 40px;
        }
      }
    }
  }

  .footer-theme2 {
    .contact-details {
      li {
        padding: 0 40px;
      }
    }

    .footer-mobile-title {
      display: none !important;
    }
  }

  .footer-theme {
    .sub-title {
      h4 {
        font-size: 16px;
      }

      .contact-list {
        li {
          line-height: 20px;
        }

        i {
          top: 17px;
        }
      }
    }
  }

  .theme-card {
    .offer-slider {
      .media {
        .media-body {
          h4 {
            margin-top: 10px;
          }

          a {
            h6 {
              margin-right: 50px;
            }
          }
        }
      }
    }
  }

  .full-box {
    .theme-card {
      .offer-slider {
        .product-box2 {
          .media {
            .media-body {
              a {
                h6 {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            img {
              height: 160px;
            }

            .media {
              .media-body {
                .color-variant {
                  li {
                    margin-top: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .blog-page {
    .blog-media {
      .blog-right {
        h4 {
          line-height: 1.2;
          margin-bottom: 10px;
        }

        h6 {
          margin-bottom: 5px;
        }

        ul {
          margin-bottom: 10px;
        }

        p {
          line-height: 1.4;
        }
      }
    }
  }

  .pro_sticky_info {
    padding: 10px;
  }

  .is_stuck {
    margin-top: 30px;
  }

  .subscribe-form {
    .form-control {
      width: 215px;
    }
  }

  .add_to_cart {

    &.top,
    &.bottom {
      .cart-inner {
        .cart_media {

          .cart_product,
          .cart_total {
            padding: 30px 14px;
          }
        }
      }
    }
  }

  // inner pages
  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-five {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }

  .timer {
    span {
      .padding-l {
        padding-left: 5px;
      }
    }
  }
}

.message_left {
  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media (max-width: 1199px) {
  .message_left {
    height: auto !important;
  }

  .h-90 {
    height: 86% !important;
  }

  .bg_bookinb {
    background-color: white !important;
  }

  header {
    &.header-gym {
      .toggle-nav {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .pixelstrap {
        >li {
          >a {
            color: black !important;
            padding: 10px 15px;
          }
        }
      }
    }

    &.left-header {
      .sidenav {
        .pixelstrap {
          li {
            &:hover {
              .nav-submenu {
                display: none;
              }

              .mega-menu-container {
                display: none;
              }
            }

            .nav-submenu {
              opacity: 1;
              display: none;

              &.opensubmenu {
                display: block;
              }

              li {
                &:hover {
                  .nav-sub-childmenu {
                    display: none;
                  }
                }

                .nav-sub-childmenu {
                  &.menu-open {
                    display: block;
                  }
                }
              }
            }

            .mega-menu-container {
              top: auto;
              left: 0px;
              margin-left: 0;
              margin-top: 0;
              min-width: auto;
              position: relative;
              width: auto;

              &.opensubmenu {
                display: block;
              }
            }
          }
        }
      }
    }

    &.header-christmas {
      .pixelstrap {
        >li {
          >a {
            color: black !important;
            padding: 10px 15px;
          }
        }
      }

      .main-navbar {
        .nav-menu {
          >li {
            >a {
              color: #222222;
            }

            &:hover {
              >a {
                color: #222222;
              }
            }
          }
        }
      }
    }

    &.left-header {
      .sidenav {
        .pixelstrap {
          li {
            .nav-submenu {
              li {
                a {
                  i {
                    font: bold 14px monospace !important;

                    &:before {
                      content: "+";
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .sidenav {
      .pixelstrap {
        li {
          ul {
            li {
              a {
                padding: 5px 35px;

                &:active,
                &:focus,
                &.highlighted {
                  padding: 5px 35px;
                }
              }
            }
          }
        }
      }
    }
  }

  .header-gym {
    .main-navbar {
      .nav-menu {
        >li {
          >a {
            color: #222222;

            &:hover,
            &:active,
            &:focus,
            &.highlighted {
              color: #222222;
            }
          }
        }
      }
    }
  }

  .portfolio-padding {
    padding-bottom: 30px;
  }

  .top-header .header-contact {
    display: none;
  }

  .form_search {
    display: none;
  }

  .pet-parallax {
    .pet-decor {
      left: 45px;
      bottom: -105px;

      img {
        width: 200px;
      }
    }
  }

  header {
    &.header-6 {
      .mobile-search {
        display: inline-block;
      }

      &.sticky {
        &.fixed {
          .toggle-nav {
            padding-top: 40px;
            padding-bottom: 40px;
          }
        }
      }
    }

    &.header-tools {
      .toggle-nav {
        padding-top: 25px;
        padding-bottom: 25px;
      }

      .pixelstrap {
        >li {
          >a {
            padding: 10px 15px !important;

            &:hover,
            &:active,
            &:focus,
            &.highlighted {
              padding: 10px 15px;
            }
          }
        }

        li {
          .lable-nav {
            top: 5px;
          }
        }
      }
    }
  }

  .sticky {
    &.fixed {

      .pixelstrap>li>a,
      .pixelstrap>li>a:hover,
      .pixelstrap>li>a:active {
        padding: 10px 15px;
      }

      .sidenav .pixelstrap>li>a,
      .sidenav .pixelstrap>li>a:hover,
      .sidenav .pixelstrap>li>a:active {
        padding: 9px 23px;
      }
    }
  }

  .footer-light {
    .subscribe {
      p {
        line-height: 1.2;
      }
    }
  }

  .footer-social,
  .social-white {
    margin-top: 25px;
  }

  .timer span {
    width: 60px;
  }

  .collection-filter-block .product-service .media svg {
    height: 30px;
    width: 30px;
  }

  .partition1 {
    margin-bottom: -25px;

    >div {
      margin-bottom: 25px;
    }
  }

  .title1 {
    h4 {
      padding-bottom: 8px;
    }

    .title-inner1 {
      padding-bottom: 12px;
      margin-bottom: 25px;
    }
  }

  .title2 {
    h4 {
      padding-bottom: 8px;
    }

    .title-inner2 {
      margin-bottom: 25px;
    }
  }

  .title3 {
    h4 {
      padding-bottom: 8px;
    }

    .title-inner3 {
      margin-bottom: 10px;
    }

    .line {
      margin-bottom: 25px;
    }
  }

  .title4 {
    .title-inner4 {
      padding-bottom: 15px;
    }

    .line {
      margin-bottom: 25px;
    }
  }

  .title-borderless {
    margin-bottom: 25px;
  }

  .about-text {
    p {
      margin-bottom: 25px;
    }
  }

  .banner-timer {
    .timer {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 30px;
      bottom: 8px;
      margin-top: 38px;
    }
  }

  .footer-social {
    li {
      // padding-right: 38px;
    }
  }

  .footer-theme2 {
    p {
      line-height: 25px;
    }

    .subscribe-block {
      padding: 10px 70px;
    }

    .footer-mobile-title {
      display: none !important;
    }

    .contact-details {
      li {
        line-height: 25px;
        padding: 0 10px;
      }
    }

    .footer-link {
      li {
        padding-right: 25px;
      }
    }

    &.section-light {
      .footer-block {
        .subscribe-white {
          padding: 50px;
        }
      }
    }
  }

  .social-white {
    li {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .service-block,
  .service-block1 {
    svg {
      width: 50px;
      height: 50px;
    }
  }

  .service-block1 {
    svg {
      margin-bottom: 15px;
    }
  }

  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            padding: 13px 27px;
          }
        }
      }
    }
  }

  .category-bg {
    .image-block {
      &.even {
        &:after {
          bottom: 87%;
        }
      }
    }

    .contain-block {
      &.even {
        &:after {
          top: 87%;
        }
      }

      &:after {
        bottom: 87%;
      }
    }
  }

  .no-slider {
    .product-box {
      flex: 0 0 33.33%;
      max-width: calc(33.33% - 30px);
      margin: 0 15px 30px;

      &:nth-last-child(-n + 4) {
        margin: 0 15px 30px;
      }

      &:nth-last-child(-n + 3) {
        margin: 0 15px 0;
      }
    }

    &.five-product {
      .product-box {
        width: 100%;
        flex: 0 0 33.33%;
        max-width: calc(33.33% - 30px);
        margin: 0 15px 30px;

        &:nth-last-child(-n + 5) {
          margin: 0 15px 30px;
        }

        &:nth-last-child(-n + 3) {
          margin: 0 15px 0;
        }
      }
    }
  }

  .theme-tab {
    .tab-title2 {
      font-size: 22px;

      &:after {
        top: 17px;
      }
    }

    .tab-content {
      .product-tab {
        .tab-box {
          flex: 0 0 50%;
          max-width: calc(50% - 10px);

          &:nth-last-child(-n + 2) {
            margin: 0 5px 0;
          }

          &:nth-last-child(-n + 4) {
            margin: 0 5px 10px;
          }

          .product-box2 {
            img {
              height: 280px;
            }
          }
        }
      }
    }
  }

  .full-banner {
    .banner-contain {
      h2 {
        font-size: 85px;
      }

      h3 {
        font-size: 50px;
      }

      h4 {
        font-size: 22px;
        padding-top: 5px;
      }
    }

    .santa-img {
      img {
        width: 200px;
      }
    }

    &.feature-banner {

      .feature-object,
      .feature-object-right {
        li {
          margin-right: 0;
        }
      }
    }
  }

  .blog-section {
    .review-box {
      .santa-img {
        display: none;
      }
    }
  }

  .collection-banner {
    .contain-banner {
      &.banner-3 {
        padding-left: 35px;
        padding-right: 35px;

        h2 {
          font-size: 25px;
        }

        h4 {
          font-size: 12px;
        }
      }

      &.banner-4 {
        padding-left: 35px;
        padding-right: 35px;

        h2 {
          font-size: 24px;
        }

        h4 {
          font-size: 16px;
        }
      }

      h2 {
        font-size: 40px;
      }
    }
  }

  .home-slider {
    .slider-details {
      width: 335px !important;
      height: 335px !important;

      h1 {
        font-size: 50px;
      }

      h2 {
        font-size: 36px;
      }

      .btn-white {
        margin-top: 0;
      }
    }

    .home {
      // height: 70vh;
    }

    .slider-contain {

      // height: 70vh;
      h1 {
        font-size: 50px;
      }
    }
  }

  .background {
    .contain-bg {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .theme-card {
    .offer-slider {
      .media {
        .media-body {
          a {
            h6 {
              margin-right: 20px;
            }
          }

          .rating {
            i {
              padding-right: 3px;
            }
          }
        }
      }
    }

    &.card-border {
      .offer-slider {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .full-box {
    .theme-card {
      .offer-slider {
        img {
          padding: 15px 0 15px 0;
        }

        .product-box2 {
          .media {
            img {
              height: 250px;
            }

            .media-body {
              .color-variant {
                margin-top: 5px;

                li {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .home-slider {
    &.fullpage {
      .home {
        .slider-contain {
          h1 {
            font-size: 35px;
          }

          p {
            max-width: 450px;
            font-size: 14px;
          }

          .btn-solid {
            padding: 10px 15px;
          }
        }
      }
    }
  }

  .about-section {
    h2 {
      font-size: 22px;
    }

    .about-text {
      p {
        line-height: 24px;
      }
    }

    .service {
      .service-block1 {
        svg {
          margin-bottom: 5px;
        }

        h5 {
          line-height: 18px;
        }
      }
    }

    .small-section {
      padding-top: 20px;
    }
  }

  .layout7-product {
    .product-box {
      .details-product {
        background-color: rgba(#ffffff, 0.5);
        padding: 5px;
      }
    }
  }

  .padding-bottom-cls {
    padding-bottom: 30px;
  }

  .layout-8 {
    padding: 0 40px;
  }

  .tools-parallax-product {
    &.full-banner {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    .tools-description {
      h3 {
        font-size: 17px;
      }
    }
  }

  .gym-product {
    .part-cls {
      >div {
        &:nth-last-child(-n + 2) {
          .product-box {
            margin-top: 30px;
          }
        }
      }
    }

    &.partition-cls {
      .row {
        >div {
          &:nth-child(-n + 4) {
            margin-bottom: 0;
          }

          .product-box {
            margin-top: 30px;
          }

          &:nth-child(-n + 2) {
            .product-box {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .tab-left {
    .theme-tab {
      display: block;

      .left-side {
        width: 100%;

        li {
          width: unset;
          border-bottom: none;
          padding: 0 5px;

          &:first-child {
            border-top: none;
          }
        }

        .tab-title {
          margin-right: 0;
          text-align: center;
        }
      }

      .tab-content-cls {
        width: 100%;
      }
    }
  }

  .absolute_banner {
    .collection-banner {
      .absolute-contain {
        h3 {
          font-size: 22px;
          margin-bottom: 0;
        }

        h4 {
          font-size: 16px;
        }
      }
    }
  }

  .absolute-product {
    .no-slider {
      .product-box {
        &:nth-last-child(-n + 4) {
          margin: 0 15px 30px;
        }

        &:nth-last-child(-n + 2) {
          margin: 0 15px 0;
        }
      }
    }
  }

  .box-product {
    .full-box {
      .row {
        >div {
          &:nth-last-child(-n + 2) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .theme-card {
    .offer-slider {
      >div {
        .media {
          &:last-child {
            img {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .service-block {
    +.service-block {
      border: none;
    }
  }

  .add_to_cart {

    &.top,
    &.bottom {
      left: -300px;
      top: 0;
      height: 100vh;
      width: 300px;

      .cart-inner {
        height: 100vh;
        overflow: auto;

        .cart_top {
          padding: 20px !important;
          margin-bottom: 20px;
        }

        .cart_media {
          padding: 0 20px !important;
          display: block;

          .cart_product {
            padding: 0;
            width: 100%;
            display: block;
            overflow-y: hidden;

            li {
              min-width: 100%;
              max-width: 100%;
              margin-right: 0;
              padding-bottom: 10px;
            }
          }

          .cart_total {
            padding: 0;
            width: 100%;
          }
        }
      }

      &.open-side {
        left: 0;
      }
    }
  }

  .blog-section {
    .review-box {
      padding: 15px;

      .review-content {
        p {
          margin-top: 0;
        }
      }

      .santa-img {
        display: none;
      }
    }
  }

  .four-slider-skeleton {
    >div {
      &:last-child {
        display: none;
      }
    }
  }

  // inner pages //
  .blog-detail-page {
    .comment-section {
      li {
        padding-top: 45px;
        padding-bottom: 45px;
      }
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      .product-filter-content {
        .search-count {
          width: 100%;
          border-left: 1px solid #dddddd;
          border-bottom: 1px solid #dddddd;
        }

        .collection-view {
          width: 20%;
          padding-right: 0 !important;
          border-left: 1px solid #dddddd;
        }

        .collection-grid-view {
          padding: 20px !important;
          padding-left: 0 !important;
          width: 20%;

          ul {
            li {
              &:last-child {
                display: none;
              }
            }
          }
        }

        .product-page-filter {
          width: 30%;
        }

        .product-page-per-view {
          width: 30%;
        }
      }
    }
  }

  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 30px;

          p {
            line-height: 1.5;
          }
        }
      }
    }
  }

  .search-product {
    >div {

      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3) {
        margin-top: 30px;
      }
    }
  }

  .cart-section {
    tbody {
      tr {
        td {
          min-width: 140px;

          .qty-box {
            .input-group {
              .form-control {
                width: 52px;
              }
            }
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          padding-right: 25px;
        }
      }
    }
  }

  .blog-page {
    .blog-media {
      margin-bottom: 20px;

      .blog-right {
        display: block;
        margin-top: 15px;

        p {
          line-height: 1.3;
        }
      }
    }
  }

  .collection-collapse-block {
    .collection-collapse-block-content {
      .collection-brand-filter {
        .category-list {
          li {
            &:first-child {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  .collection-filter-block {
    .product-service {
      .media {
        .media-body {
          h4 {
            font-size: 16px;
          }
        }
      }
    }
  }

  .product-right {
    h2 {
      font-size: 20px;
    }
  }

  .tab-product {
    .tab-content {
      &.nav-material {
        .single-product-tables {
          table {
            width: 50%;
          }
        }
      }
    }
  }

  .product-description-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }

    .product-icon {
      .product-social {
        li {
          padding-right: 4px;
        }
      }

      .wishlist-btn {
        span {
          padding-left: 4px;
        }
      }
    }
  }

  .product-form-box {
    .border-product {
      padding-top: 10px;
      padding-bottom: 15px;
    }

    .timer {
      padding-left: 17px;

      span {
        width: 55px;
      }
    }

    .product-buttons {

      .btn-solid,
      .btn-outline {
        padding: 5px 9px;
      }
    }
  }

  .product-accordion {
    .single-product-tables {
      table {
        width: 70%;
      }
    }
  }

  .tab-product {
    .flex-column {
      flex-direction: unset !important;
      justify-content: center;
    }

    .nav-material {
      &.nav-tabs {
        border-bottom: 1px solid #dddddd;
        border-right: 0;

        .nav-item {
          .nav-link {
            &.active {
              background-color: unset;
            }
          }
        }
      }
    }
  }

  .tab-border {
    padding-top: 0;
  }

  .theme-card {
    .offer-slider {
      img {
        height: 120px;
      }
    }
  }

  .dashboard-section {
    .counter-section {
      .counter-box {
        padding: 20px;

        img {
          height: 40px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 767px) {
  .partition-f {
    >div {
      &+div {
        &+div {
          margin-top: 30px;
        }
      }
    }
  }

  .lookbook {
    .lookbook-block {
      .lookbook-dot {
        .dot-showbox {
          width: 80px;

          .dot-info {
            h5 {
              line-height: 15px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1600px) and (max-width: 1754px) {
  .position_stort {
    left: 10px !important;
  }
}

@media (min-width: 1440px) {
  .cutyesiur p {
    font-size: 17px;
    line-height: 27px;
  }

  .header {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1700px !important;
    }
  }

  .position_stort {
    position: fixed !important;
    display: block !important;
    left: 5%;
    top: 117px;
  }

  .container {
    padding: 0 65px;
  }
}

@media (max-width: 991px) {
  .pagination {
    justify-content: start !important;
  }

  .nav-tabs {
    justify-content: start !important;
  }

  .model_close_icon {
    right: 0px;
    top: -14px;
    width: 30px !important;
    height: 30px !important;
  }

  h3,
  .h3 {
    font-size: 15px !important;
  }

  body .modal-body {
    padding: 10px !important;
  }

  body .input-container .form-control {
    font-size: 13px !important;
  }

  label {
    font-size: 13px !important;
  }

  .model_close_icon img {
    width: 14px !important;
    height: 14px !important;
    object-fit: cover !important;
  }

  .lft_side {
    display: none !important;
  }

  .mobile_sidebar {
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    margin-right: 10px;
  }

  body .home_slider .slick-dots {
    display: block !important;
  }

  .slick-dots {
    display: none !important;
  }

  .border_boton {
    display: none !important;
  }

  .section_tab .slick-prev,
  .section_tab .slick-next {
    display: none !important;
  }

  header {
    &.header-tools {
      position: relative;
      top: 0;

      .top-header {
        background-color: #125453;

        .container {
          background-color: transparent;
        }
      }

      .logo-menu-part {
        background-color: white;

        >.container {
          border-bottom: none;
        }
      }

      &.stickycls {
        position: fixed;
        background-color: white;
      }
    }

    &.header-christmas {
      position: relative;
      background-color: #e34041;
      margin-bottom: -50px;
    }
  }

  .masonry-grid-col {
    .isotopeSelector {
      width: 33.33% !important;
    }
  }

  .outsideImage {
    display: flex;
    flex-direction: column;

    .imgae-outside-thumbnail {
      order: -1;
    }
  }

  .collection-wrapper {
    .filter-col {
      padding: 0;

      .filter-main-btn {
        span {
          width: auto;
          border-radius: 0;
          font-size: 18px;
          background-color: var(--theme-deafult);
          padding: 5px 16px;
          color: #ffffff;
          margin-bottom: 20px;
          display: inline-block;
          text-transform: uppercase;
        }
      }
    }
  }

  #filterpopup {

    .theme-card,
    .collection-sidebar-banner {
      border: none;
    }
  }

  .videoClass {
    object-fit: none;
    overflow: unset;
    height: 100vh;
  }

  .portfolio-padding {
    padding-bottom: 20px;
  }

  .pet-parallax {
    .pet-decor {
      left: 34px;
      bottom: -86px;

      img {
        width: 165px;
      }
    }

    .banner-contain {
      p {
        max-width: 100%;
      }
    }
  }

  .m-sm-t-2 {
    margin-top: 2rem;
  }

  .service-block p {
    line-height: 1.2;
  }

  h2 {
    font-size: 28px;
  }

  .margin-add {
    margin-top: 0;
  }

  .small-section {
    padding: 30px 0;
  }

  .product-full-tab {
    padding-top: 50px !important;
  }

  .product-slick,
  .rtl-product-slick,
  .product-right-slick,
  .rtl-product-right-slick {
    .slick-slide {
      img {
        width: 100% !important;
      }
    }
  }

  hr.style1 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  h4 {
    font-size: 16px;
  }

  .blog-details {
    h4 {
      margin-top: 17px;
      font-size: 13px;
    }

    p {
      font-size: 15px;
      line-height: 1.3;
      margin-top: 10px;
    }
  }

  .service-block,
  .service-block1 {
    svg {
      margin-bottom: 20px;
      height: auto;
      width: 46px;
    }
  }

  .partition_3 {
    >div {
      &+div {
        margin-top: 30px;
      }
    }
  }

  .partition4 {
    >div {
      &+div {
        &+div {
          margin-top: 30px;
        }
      }
    }
  }

  .banner-timer {
    .banner-text {
      h2 {
        font-size: 20px;
      }
    }

    .timer {
      span {
        width: 65px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter {
        .open-popup {
          &.open {
            z-index: 999;

            .collection-filter {
              left: 0;
            }
          }
        }
      }
    }
  }

  .about-text {
    p {
      line-height: 24px;
    }
  }

  .footer-light {
    .subscribe {
      text-align: center;
      justify-content: center;
      border: none;
      margin-bottom: 5px;

      p {
        display: none;
      }
    }

    .subscribe-form {
      justify-content: center;
      margin-bottom: 0;
    }
  }

  .footer-theme {
    .col {
      max-width: 50%;
      flex-basis: unset;
    }

    .footer-logo {
      margin-bottom: 30px;
    }

    .sub-title {
      h4 {
        margin-bottom: 10px;
      }
    }
  }

  .footer-social {
    margin-top: 15px;
  }

  .subscribe-wrapper {
    order: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-theme2 {
    .footer-mobile-title {
      display: none !important;
    }

    h4 {
      padding-bottom: 10px;
    }

    .col {
      flex-basis: unset;
      padding: 20px 0;
    }

    &.section-light {
      .footer-block {
        h4 {
          padding-bottom: 15px;
        }

        .subscribe-white {
          border: none;
        }
      }
    }

    .footer-logo {
      margin-bottom: 20px;
    }

    .subscribe-block {
      border: none;
    }
  }

  footer {
    &.footer-5 {
      .footer-theme2 {
        .subscribe-block {
          border-left: none;
          border-right: none;
        }
      }
    }

    &.footer-black {
      .below-section {
        padding-top: 95px;
      }

      &.footer-light {
        .subscribe {
          border-right: none;
        }
      }
    }

    &.footer-classic {
      .upper-footer {
        .small-section {
          padding: 30px 0;
        }
      }
    }
  }

  .social-white {
    li {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            padding: 5px 15px;
            font-size: 30px;
          }
        }
      }
    }
  }

  .category-bg {
    .image-block {
      &.even {
        &:after {
          bottom: 85%;
        }
      }

      &:after {
        width: 35px;
      }

      &:hover {
        &:after {
          height: 70px;
        }
      }
    }

    .contain-block {
      h2 {
        font-size: 20px;
        margin-top: 8px;
      }

      &:after {
        width: 35px;
      }

      &.even {
        &:after {
          top: 85%;
        }
      }

      .category-btn {
        margin-bottom: 15px;
        margin-top: 15px;
        letter-spacing: 0.2em;
        padding: 10px 15px;
      }

      &:after {
        bottom: 85%;
      }

      &:hover {
        &:after {
          height: 70px;
        }
      }
    }
  }

  .no-slider {
    .product-box {
      flex: 0 0 50%;
      max-width: calc(50% - 30px);
      margin: 0 15px 30px !important;

      &:nth-last-child(-n + 2) {
        margin: 0 15px 0 !important;
      }
    }

    &.five-product {
      .product-box {
        flex: 0 0 50%;
        max-width: calc(50% - 30px);
        margin: 0 15px 30px !important;

        &:nth-last-child(-n + 2) {
          margin: 0 15px 0 !important;
        }
      }
    }
  }

  .full-banner {
    padding-top: 180px;
    padding-bottom: 180px;

    &.parallax-layout {
      padding-top: 180px;
      padding-bottom: 180px;
    }

    &.feature-banner {

      .feature-object,
      .feature-object-right {
        li {
          &:nth-child(2) {
            padding-right: 0;
            padding-left: 0;
          }

          &:nth-child(3) {
            padding-right: 0;
            padding-left: 0;
          }
        }
      }

      .center-img {
        display: none;
      }

      .feature-object-right {
        margin-left: 0;
      }

      .banner-decor {

        .left-img,
        .right-img {
          img {
            width: 100px;
          }
        }
      }

      h2 {
        margin-bottom: 30px;
      }
    }

    .decor {
      display: none;
    }

    .banner-contain {
      h2 {
        font-size: 60px;
      }

      h3 {
        font-size: 35px;
      }

      h4 {
        font-size: 20px;
        padding-top: 5px;
      }
    }

    .banner-decor {

      .left-img,
      .right-img {
        img {
          width: 100px;
        }
      }
    }
  }

  .collection-banner {
    .contain-banner {
      padding-left: 60px;
      padding-right: 60px;

      &.banner-3 {
        padding-left: 30px;
        padding-right: 30px;

        h2 {
          font-size: 20px;
        }
      }

      &.banner-4 {
        padding-left: 40px;
        padding-right: 40px;

        h2 {
          font-size: 18px;
        }

        h4 {
          font-size: 18px;
        }
      }

      h2 {
        font-size: 25px;
      }
    }
  }

  .collection-collapse-block {}

  .center-image-width {
    .product-box {
      width: 70%;
      margin: 0 auto;
    }
  }

  .home-slider {
    .slider-details {
      top: 20%;
      padding: 0;
      width: 260px !important;
      height: 260px !important;

      h1 {
        line-height: 1;
        font-size: 30px;
        margin: 5px 0;
      }

      h2 {
        font-size: 24px;
      }

      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 18px;
        line-height: 1;
        margin-bottom: 2px;
      }

      .btn-white {
        padding: 7px 14px;
        margin-top: 5px;
      }
    }

    .home {
      // height: 65vh;
    }

    .slider-contain {

      // height: 65vh;
      h1 {
        font-size: 36px;
      }

      .btn-solid,
      .btn-outline {
        margin-top: 17px;
      }
    }

    &:hover {
      .slick-next {
        right: 90px;
      }

      .slick-prev {
        left: 90px;
      }
    }
  }

  .background {
    .contain-bg {
      padding-top: 25px;
      padding-bottom: 25px;

      h4 {
        font-size: 16px;
      }
    }
  }

  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            img {
              height: 210px;
            }
          }
        }
      }
    }
  }

  .beauty-about {
    img {
      margin: 0 auto;
    }

    .about-text {
      p {
        text-align: center;
      }
    }
  }

  .theme-card {
    .offer-slider {
      img {
        padding: 10px 10px 10px 0;
      }

      >div {
        .media {
          &:last-child {
            img {
              //margin-bottom: 0;
            }
          }
        }
      }
    }

    &.card-border {
      .offer-slider {
        img {
          padding: 10px 10px 10px 30px;
        }
      }
    }
  }

  .multiple-slider {
    >div {
      &:nth-child(-n + 2) {
        margin-bottom: 30px;
      }
    }
  }

  .full-box {
    .center-slider {
      .offer-slider {
        .product-box {
          .product-info {
            h4 {
              padding-bottom: 5px;
            }

            .btn-outline {
              padding: 7px 20px;
            }
          }
        }
      }
    }

    .theme-card {
      .offer-slider {
        .product-box2 {
          .media {
            img {
              height: 130px;
            }

            .media-body {
              .color-variant {
                li {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .layout-8 {
    .layout-8-bg {
      padding: 0;
    }
  }

  .alert {
    max-width: 45% !important;
  }

  .theme-modal {
    &.modal-dialog {
      .modal-content {
        .modal-body {
          .modal-bg {
            .offer-content {
              img {
                margin-bottom: 20px;
              }

              h2 {
                margin-bottom: 18px;
              }
            }
          }
        }
      }
    }

    &.cart-modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bg {
              padding: 15px;

              &.addtocart {
                .media {
                  .media-body {
                    .buttons {
                      a {
                        font-size: 13px;
                        padding: 4px 10px;
                        margin: 3px 6px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.exit-modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bg {
              padding: 20px;
            }
          }
        }
      }
    }
  }

  .parallax-cls {
    .banner-contain {
      background-color: rgba(#ffffff, 0.5);
      padding: 35px;
    }

    section {
      .banner-contain {
        margin-top: 0;
      }
    }
  }

  .about-section {
    padding-top: 30px;

    h2 {
      text-align: center;
    }
  }

  .product-box {
    .cart-info {
      bottom: 20px;
    }
  }

  .absolute-banner {
    margin-top: -60px;
  }

  .tools_slider {
    .home-slider {
      .home {

        .tools-parts,
        .tools-parts1 {
          margin-top: 20px;

          img {
            width: 180px;
          }
        }

        .slider-contain {
          margin-left: 40px;
          margin-top: 0;
        }

        .tools-parts {
          right: 0;
        }

        .tools-parts1 {
          right: 10%;
        }

        #tools-move2 {
          right: 7%;
        }
      }
    }
  }

  .bg-title {
    .theme-tab {
      .bg-title-part {
        margin-top: 30px;
      }
    }
  }

  .absolute_banner {
    .collection-banner {
      .absolute-contain {
        h3 {
          font-size: 16px;
        }

        h4 {
          font-size: 14px;
        }
      }
    }
  }

  .blog-section {
    .review-box {
      margin-top: 30px;

      .review-content {
        p {
          margin-bottom: 45px;
        }
      }
    }
  }

  .four-slider-skeleton {
    >div {
      &:nth-last-child(2) {
        display: none;
      }
    }
  }

  // inner pages //
  .product-right {
    &.product-form-box {
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .testimonial {
    .testimonial-slider {
      .media {
        .media-body {
          padding: 0 30px;
          height: 100%;
          align-self: center;

          p {
            margin-bottom: 0;
          }
        }
      }

      .slick-track {
        .slick-slide {
          &:nth-child(even) {
            .media {
              border-left: none;
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .blog-detail-page {
    .blog-advance {
      ul {
        margin-bottom: 20px;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      border-top: none;

      .product-filter-content {
        .search-count {
          border-top: 1px solid #dddddd;
        }

        .collection-view {
          display: none;
        }

        .collection-grid-view {
          display: none;
        }

        .product-page-per-view,
        .product-page-filter {
          width: 50%;

          &:before {
            right: 15px !important;
          }

          select {
            border-left: 1px solid #dddddd;
            padding: 21px 34px 21px 34px;
            border-top: none;
            border-bottom: none;
          }
        }

        .product-page-per-view {
          select {
            border-right: none !important;
          }
        }
      }

      .popup-filter {

        .product-page-per-view,
        .product-page-filter {
          border-top: 1px solid #dddddd;
        }
      }
    }
  }

  .collection-product-wrapper {
    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 55%;
          padding-top: 10px;
          padding-bottom: 10px;
          text-align: center;
          margin-bottom: 20px;
          background: none var(--theme-deafult);

          .open-popup {
            text-align: left;
          }

          >a {
            color: #ffffff;
          }

          .collection-filter {
            height: 100vh;
            left: -350px;
            transition: all 0.5s ease;
          }
        }

        .search-count {
          width: 100%;
          padding: 10px 0;
          text-align: center;
        }

        .collection-view,
        .collection-grid-view {
          display: none;
        }

        .product-page-per-view,
        .product-page-filter {
          width: 50%;

          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:before {
            top: 13px;
          }
        }

        .product-page-filter {
          &:before {
            left: unset;
            right: 35px;
          }
        }
      }
    }
  }

  .top-banner-content {
    h4 {
      font-size: 20px;
    }
  }

  .filter-main-btn {
    display: block;
  }

  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -570px;
    background-color: white;
    z-index: 999999 !important;
    overflow-y: scroll;
    padding: 15px 0 15px 15px;
    width: 300px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px #ddd;

    .collection-sidebar-banner {
      text-align: center;
    }

    .theme-card {
      padding-left: 30px;
      padding-right: 30px;
    }

    .collection-sidebar-banner {
      padding: 0 30px;
    }
  }

  .collection-filter-block {
    border: none;
  }

  .collection-mobile-back {
    display: block;
  }

  .collection {
    .section-t-space {
      padding-top: 30px;
    }

    .partition-collection {
      >div {
        &:nth-last-child(1) {
          margin-top: 30px;
        }

        &:nth-last-child(2) {
          margin-top: 30px;
        }
      }
    }
  }

  .right-login {
    margin-top: 30px;
  }

  .contact-page {
    .map {
      iframe {
        height: 350px;
      }
    }

    .contact-right {
      padding-bottom: 0;

      ul {
        li {
          padding-left: 0;
          border-bottom: 1px solid #dddddd;
          text-align: center;
          padding-bottom: 10px;
          margin-top: 15px;

          .contact-icon {
            position: relative;
            margin: 0 auto;
            border-right: 0;
          }

          p {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .cart-section {
    .cart-table {
      thead {
        th {
          &:last-child {
            display: none;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          min-width: 135px;

          &:last-child {
            display: none;
          }
        }
      }
    }

    .cart-buttons {
      >div {
        &:last-child {
          padding-right: 15px;
        }
      }
    }

    tfoot {
      tr {
        td {
          padding-right: 0;
        }
      }
    }
  }

  .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 138px;
        }
      }
    }
  }

  .tab-product {
    padding-top: 20px;
  }

  .product-right {
    h2 {
      margin-top: 15px;
    }
  }

  .pro_sticky_info {
    border: none;
    padding: 10px 0;
  }

  .product-right {
    text-align: center;
    margin: 20px 0 10px 0;

    .detail-section,
    .product-icon {
      justify-content: center;
    }

    .product-description {
      .qty-box {
        justify-content: center;
      }
    }

    .size-text {
      text-align: left;
    }

    .timer {
      text-align: left;
    }

    .product-icon {
      .product-social {
        li {
          padding-right: 20px;
        }
      }
    }
  }

  .product-form-box {
    margin-bottom: 10px;
  }

  .tab-product {
    .nav-material {
      &.nav-tabs {
        justify-content: center;
      }
    }
  }

  .dashboard-left {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 #dddddd;

    .block-title {
      h2 {
        display: none;
      }
    }

    .block-content {
      border: none;
      padding: 0;
      margin-top: 20px;

      ul {
        li {
          &:hover {
            padding-left: 0;
          }
        }
      }
    }
  }

  .account-sidebar {
    display: block;
  }

  .tools-parallax-product {
    .tools-description {
      h3 {
        text-align: center;
      }

      .tools-form {
        text-align: center;

        .search-box {
          margin: 20px auto 20px;
        }

        .btn-find {
          background-size: 1100px;
        }
      }
    }

    .tools-grey {
      order: -1;
      margin-bottom: 30px;
    }

    &.full-banner {
      padding-top: 75px;
      padding-bottom: 75px;
    }
  }

  .tools_product {
    .multiple-slider {
      >div {
        &:nth-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }

    .tools-grey {
      margin-top: 30px;
    }

    .banner-tools {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .tools-service {
    .service-block {
      +.service-block {
        border-left: none;
      }
    }

    .service-block,
    .service-block1 {
      svg {
        margin-bottom: 0;
      }
    }
  }

  .service_slide {
    .service-home {
      position: relative;

      .service-block1 {
        padding: 0 4px;
      }
    }

    .partition4 {
      >div {
        +div {
          margin-top: 0;

          +div {
            margin-top: 30px;
          }
        }
      }
    }

    .home-slider {
      .slider-contain {
        // height: 65vh;
      }
    }
  }

  .bundle {
    .bundle_img {
      justify-content: center;
    }

    .bundle_detail {
      .theme_checkbox {
        padding: 0 20%;
      }
    }
  }

  .dashboard-section {
    .dashboard-sidebar {
      .faq-tab {
        .nav-tabs {
          display: inline-block;
          width: auto;
          background-color: #f8f8f8;
        }
      }
    }

    .faq-tab {
      .nav-tabs {
        .nav-item {
          .nav-link {
            &.active {
              border-bottom: 2px solid var(--theme-deafult);
              border-right: none;
            }
          }
        }
      }
    }

    .counter-section {
      margin-top: 20px;
    }
  }

  .vendor-profile {
    .profile-left {
      display: block;

      .profile-image {
        margin: 0 auto;
        width: 100%;
      }

      .profile-detail {
        margin: 0 auto;
        text-align: center;
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid #efefef;
        padding-top: 15px;
        width: 100%;
        margin-top: 15px;
      }

      .vendor-contact {
        width: 100%;
        text-align: center;
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        border-top: 1px solid #efefef;
        padding-top: 15px;
        margin-top: 15px;

        .footer-social {
          justify-content: center;

          li {
            padding: 0 5px;
          }
        }
      }
    }
  }

  .vendor-cover {
    .bg-size {
      height: 270px;
    }
  }

  .become-vendor {
    h4 {
      margin-bottom: 20px;
    }

    .step-bg {
      .row {
        margin-left: -15px;
        margin-right: -15px;

        >div {
          padding-left: 15px;
          padding-right: 15px;

          &:nth-child(2) {
            margin-top: 0;
            margin-bottom: 30px;
          }

          &:nth-child(1) {
            margin-bottom: 30px;
          }

          &:nth-child(3) {
            margin-bottom: 0;
          }
        }
      }

      &:before {
        transform: rotate(90deg);
      }
    }
  }

  .checkout-page {
    .checkout-form {
      .checkout-details {
        margin-top: 30px;
      }
    }
  }

  .typography_section {
    .row {
      >div {
        &:first-child {
          .typography-box {
            &:last-child {
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }

  .tab-product {
    &.vertical-tab {
      padding-top: 0;
    }
  }
}

@media (max-width: 767px) {
  .logo_booking {
    width: 131px !important;
    height: 42px !important;
    object-fit: cover !important;
  }

  header {
    &.header-metro {
      .metro {
        .layout3-menu {
          max-width: 540px;
        }
      }
    }

    &.left-header {
      .top-header {
        .header-dropdown {
          li {
            padding: 15px;
          }
        }
      }

      .main-menu {
        .menu-right {
          .icon-nav {
            li {
              padding-left: 15px;
            }
          }
        }
      }
    }
  }

  .pet-parallax {
    .pet-decor {
      display: none;
    }
  }

  .masonry-grid-col {
    .isotopeSelector {
      width: 50% !important;
    }
  }

  .portfolio-section {
    &.metro-section {
      .product-box {
        .cart-wrap {
          a {
            i {
              opacity: 1;
              font-size: 16px;
              padding: 5px;
            }
          }

          button {
            i {
              font-size: 16px;
              padding: 5px;
            }
          }
        }

        .product-detail {
          opacity: 1;
          bottom: 15px;
          background-color: rgba(#ffffff, 0.5);

          h6 {
            color: #000000;
          }
        }

        &:hover {
          .product-detail {
            opacity: 1;
          }

          .cart-wrap {
            a {

              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                i {
                  animation: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .portfolio-padding {
    padding-bottom: 10px;
  }

  .pet-parallax {
    .full-banner {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }

  .pet-layout-footer {
    .footer-theme2 {
      .footer-link {
        .social-white {
          margin-top: 30px;
          margin-bottom: 20px;

          li {
            display: unset;
          }
        }
      }
    }
  }

  .j-box {
    .product-box {
      .cart-info {
        bottom: 0;
        position: relative;
        opacity: 1;

        a {
          i {
            padding: 10px;
          }
        }
      }
    }
  }

  .pets-box {
    .product-box {
      .img-wrapper {
        .cart-info {
          transform: none;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }

  .addtocart_count {
    .product-box {
      &:hover {
        .cart-info {
          a {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              i {
                animation: none;
              }
            }
          }
        }
      }
    }

    .center-slider {
      .offer-slider {

        .add-button,
        .cart-info {
          display: none;
        }

        .product-box {
          .product-detail {
            .rating {
              margin-top: 25px;
            }
          }
        }
      }
    }
  }

  .breadcrumb-section {

    .page-title,
    .breadcrumb {
      justify-content: center;
      padding-bottom: 0;
      margin-bottom: -4px;
    }
  }

  .layout3-menu {
    max-width: 100%;

    .main-menu {
      .brand-logo {
        img {
          height: 25px;
        }
      }
    }
  }

  .product-full-tab {
    padding-top: 30px !important;
  }

  .small-section {
    padding: 20px 0;
  }

  .theme-tab {

    .tab-title,
    .tab-title2 {
      a {
        text-transform: capitalize;
      }
    }
  }

  .about-cls {
    .service {
      &.border-section {
        border-bottom: none;
      }

      &.small-section {
        padding-bottom: 0;
      }
    }
  }

  .service-block {
    .media {
      display: block;
      text-align: center;
      padding-top: 0;
      padding-bottom: 0;
    }

    svg {
      margin-right: 0;
    }

    +.service-block {
      border-left: 0;
      margin-top: 20px;
    }

    &:last-child {
      .media {
        .media-body {
          p {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .partition2 {
    margin-bottom: -25px;

    >div {
      margin-bottom: 25px;
    }
  }

  .partition3 {
    >div {
      &+div {
        margin-top: 30px;
      }
    }
  }

  .partition4 {
    >div {
      &+div {
        margin-top: 30px;
      }
    }
  }

  .center-slider {
    border-left: none;
    border-right: none;
  }

  .about-text {
    p {
      line-height: 25px;
    }
  }

  .banner-timer {
    padding-top: 5px;
    padding-bottom: 5px;

    .banner-text {
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: rgba(255, 255, 255, 0.65);

      h2 {
        font-size: 15px;
      }
    }

    .timer-box {
      text-align: center;
    }

    .timer {
      margin-top: 5px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 20px;

      p {
        font-size: 14px;
      }

      span {
        width: 40px;

        .padding-l {
          padding-left: 10px;
        }
      }
    }
  }

  .darken-layout,
  .dark-layout {
    .footer-title {
      // border-bottom: 1px solid $border-bottom-g;
    }

    .subscribe-wrapper {
      padding-bottom: 10px;
    }
  }

  .footer-title {
    margin-top: 10px;
    text-align: left;
    // border-bottom: 1px solid #dddddd;
    position: relative;

    &.footer-mobile-title {
      margin-top: 0;
    }

    h4 {
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
    }

    &.active {
      .according-menu {
        font: normal normal normal 14px/1 FontAwesome;

        &:before {
          content: "\f106";
          position: absolute;
          right: 2px;
          top: 2px;
        }
      }
    }

    .according-menu {
      font: normal normal normal 14px/1 FontAwesome;

      &:before {
        content: "\f107";
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }

  .banner-slider {
    .home-banner {
      >div {
        img {
          margin-top: 30px;
        }

        &:last-child {
          img {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .lookbook {
    .row {
      >div {
        &:last-child {
          .lookbook-block {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .lookbook-section {
    .row {
      >div {
        &:first-child {
          .lookbook-img {
            >div {
              &:last-child {
                margin-bottom: 25px;
              }
            }
          }
        }
      }
    }

    .lookbook-img {
      >div {
        &:last-child {
          img {
            margin-top: 0;
          }
        }
      }
    }
  }

  .full-scroll-footer {
    .sub-footer {
      p {
        padding: 0;
        line-height: 25px;
      }
    }
  }

  .box-layout-body {
    .box-layout {
      margin-top: -40px;
    }
  }

  .layout-20 {
    padding: 15px;
  }

  .home-slider {
    .slider-details {
      top: 15%;
      right: 8%;
      padding: 0px;
      width: 225px !important;
      height: 225px !important;

      h1 {
        font-size: 25px;
        margin: 5px 0;
      }

      h2 {
        font-size: 20px;
      }

      h3 {
        font-size: 18px;

        &:before,
        &:after {
          width: 8px;
          height: 8px;
          top: 5px;
        }
      }

      h4 {
        font-size: 16px;
      }

      .btn-white {
        padding: 5px 10px;
        font-size: 14px;
      }
    }

    &.fullpage {
      .slick-dots {
        display: flex;
        bottom: 70px;
        top: unset;
        left: 50%;
        transform: translateX(-50%);

        li {
          button {
            &:before {
              color: #0a0100;
            }
          }
        }
      }
    }
  }

  .footer-contant {
    text-align: left;

    .footer-logo {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    li {
      text-align: left;
    }

    .footer-social {
      ul {
        margin: unset;

        li {
          // padding-right: 25px;
          padding-left: 0;
        }
      }
    }
  }

  .social-white {
    li {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .footer-theme {
    .footer-mobile-title {
      display: block !important;
    }

    text-align: center;

    .col {
      max-width: 100%;
      flex-basis: unset;
    }

    .footer-social {
      margin-bottom: 15px;
    }

    .sub-title {
      li {
        padding-top: 7px;
      }

      .contact-list {
        i {
          position: relative;
          top: 0;
          margin-right: 10px;
        }

        li {
          padding-left: 0;
        }
      }
    }
  }

  .footer-social {
    ul {
      margin: 0 auto;
    }

    li {
      // padding: 0 15px;
    }
  }

  .footer-end {
    text-align: center;
  }

  .sub-footer {
    .payment-card-bottom {
      justify-content: center;
      padding-bottom: 25px;
    }
  }

  .subscribe-wrapper {
    margin: 10px 0 0 0;
    order: 0;
  }

  .footer-theme2 {
    p {
      margin-bottom: 10px;
    }

    .footer-mobile-title {
      display: block !important;
    }

    .p-set {
      padding: 0 15px !important;
    }

    .col {
      padding: 0;
    }

    .footer-link {
      li {
        display: flex;
        padding-top: 10px;
      }

      h4 {
        padding-bottom: 10px;
      }
    }

    .contact-details {
      li {
        padding-left: 0;
        padding-top: 10px;
      }
    }

    .footer-link-b {
      li {
        display: flex;
        padding-top: 10px;
      }

      h4 {
        margin-top: 0;
        padding-bottom: 10px;
      }
    }

    &.section-light {
      .footer-block {
        h4 {
          padding-bottom: 10px;
        }

        .subscribe-white {
          padding: 40px 65px;

          h2 {
            margin-bottom: 20px;
          }

          .form-group {
            display: flex;

            .btn-solid,
            .btn-outline {
              margin-top: 0;
            }
          }
        }
      }
    }

    .subscribe-block {
      h2 {
        margin-bottom: 20px;
      }
    }

    h4 {
      padding-bottom: 10px;
    }
  }

  .darken-layout {
    .footer-theme {
      .footer-mobile-title {
        h4 {
          color: #ffffff;
        }
      }
    }
  }

  .dark-layout {
    .small-section {
      padding-bottom: 40px;
    }

    .subscribe-wrapper {
      order: -1;
    }

    .footer-theme2 {
      .subscribe-wrapper {
        .subscribe-block {
          padding: 10px 60px;

          h2 {
            display: none;
          }

          .form-group {
            display: flex;

            .btn-solid,
            .btn-outline {
              margin-top: 0;
            }
          }
        }
      }
    }
  }

  .social-white {
    margin-bottom: 10px;
  }

  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }

  .box-layout {
    .small-section {
      padding-top: 0;
    }
  }

  .white-layout {
    .footer-theme2 {
      >div {
        &:nth-child(2) {
          order: -1;
        }
      }

      &.footer-border {
        padding: 0;
        border: none;
      }

      .footer-block {
        .footer-container {
          max-width: 540px;
          width: 100%;

          .footer-mobile-title {
            margin-top: 20px;
          }
        }

        .subscribe-white {
          h2 {
            display: none;
          }
        }
      }
    }
  }

  .category-bg {
    padding: 30px 40px;

    .image-block {
      &.even {
        &:after {
          bottom: 85%;
        }
      }

      &:after {
        width: 25px;
        margin-top: -25px;
      }

      &:hover {
        &:after {
          height: 50px;
        }
      }
    }

    .contain-block {
      h2 {
        font-size: 18px;
        margin-top: 8px;
      }

      h6 {
        letter-spacing: 0.1em;
        font-size: 12px;
      }

      &:after {
        width: 25px;
      }

      &.even {
        &:after {
          top: 87%;
        }
      }

      .category-btn {
        margin-bottom: 10px;
        margin-top: 10px;
        letter-spacing: 0.1em;
        padding: 5px 15px;
      }

      &:after {
        bottom: 87%;
      }

      &:hover {
        &:after {
          height: 50px;
        }
      }
    }
  }

  .layout9-box {
    .product-box {
      .img-block {
        min-height: unset;
      }
    }
  }

  .product-box,
  .product-wrap {
    .cart-detail {
      top: 5px;
      right: 10px;
    }
  }

  .product-box {
    .img-block {
      min-height: 350px;
    }

    .cart-info,
    .cart-detail {
      opacity: 1;
    }

    .img-wrapper {
      .cart-box {
        opacity: 1;
      }
    }

    &:hover {
      .img-wrapper {
        .cart-box {
          animation: none;
        }
      }

      .cart-info,
      .cart-detail {
        button {
          animation: none;
        }

        a {
          i {
            animation: none;
          }

          &:nth-child(2) i {
            animation: none;
          }

          &:nth-child(3) i {
            animation: none;
          }

          &:nth-child(4) i {
            animation: none;
          }
        }
      }
    }
  }

  .theme-tab {
    .tab-title2 {
      font-size: 20px;

      li {
        padding-left: 15px;
        padding-right: 15px;
      }

      &:after {
        top: 15px;
      }
    }
  }

  .multiple-slider {
    >div {
      &:nth-child(-n + 3) {
        margin-bottom: 30px;
      }
    }
  }

  .full-banner {
    padding-top: 120px;
    padding-bottom: 120px;

    &.parallax-layout {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .banner-contain {
      h2 {
        font-size: 75px;
      }
    }

    &.feature-banner {
      .banner-decor {

        .left-img,
        .right-img {
          display: none;
        }
      }

      h2 {
        margin-bottom: 35px;
      }
    }

    .santa-img {
      img {
        width: 125px;
      }
    }
  }

  .collection-banner {
    .contain-banner {
      padding-left: 60px;
      padding-right: 60px;

      &.banner-3 {
        padding-left: 40px;
        padding-right: 40px;

        h2 {
          font-size: 20px;
        }

        h4 {
          font-size: 18px;
        }
      }

      &.banner-4 {
        padding-left: 45px;
        padding-right: 45px;

        h2 {
          font-size: 18px;
        }
      }

      h2 {
        font-size: 35px;
      }
    }
  }

  .home-slider {
    .home {
      // height: 60vh;
    }

    .slider-contain {

      // height: 60vh;
      h1 {
        font-size: 35px;
        margin-top: 5px;
      }

      .btn-solid,
      .btn-outline {
        margin-top: 10px;
        padding: 10px 25px;
      }
    }

    &:hover {
      .slick-next {
        right: 80px;
      }

      .slick-prev {
        left: 80px;
      }
    }
  }

  .background {
    .col {
      flex-basis: unset;
    }

    .contain-bg {
      margin: 10px 0;
    }
  }

  .full-box {
    .center-slider {
      .offer-slider {
        text-align: center;

        .product-box {
          border: none;
          display: inline-block;

          .img-wrapper {
            text-align: center;

            img {
              height: 200px;
            }
          }

          &:hover {
            box-shadow: none;
          }
        }
      }
    }

    .theme-card {
      .offer-slider {
        .sec-1 {
          display: flex;

          .product-box2 {
            .media {
              .media-body {
                .color-variant {
                  li {
                    height: 18px;
                    width: 18px;
                    padding-right: 3px;
                  }
                }
              }
            }

            +.product-box2 {
              border-top: none;
            }
          }
        }
      }
    }
  }

  .theme-tab {

    .tab-title,
    .tab-title2 {
      li {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            img {
              height: 150px;
              padding: 5px;
            }
          }
        }
      }
    }
  }

  .theme-modal {
    &.modal-dialog {
      .modal-content {
        .modal-body {
          .modal-bg {
            .age-content {
              h2 {
                margin-bottom: 20px;
                padding-bottom: 20px;
              }

              h4 {
                margin-bottom: 20px;
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .theme-modal {
    &.cart-modal {
      .product-section {
        display: none;
      }
    }
  }

  .filter-main-btn {
    span {
      font-size: 16px;
    }
  }

  .tap-top {
    bottom: 70px;
    right: 20px;
  }

  .filter-main-btn span {
    right: 20px;
    background-color: #125453;
  }

  .footer-theme2 {

    .footer-link,
    .footer-link-b {
      li {
        padding-left: 0;
      }
    }
  }

  .tools-parallax-product {
    .tools-grey {

      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }

    &.full-banner {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .tools-grey {
    .product-box {
      .img-wrapper {
        padding-bottom: 35px;
      }

      .cart-info {
        bottom: 10px;

        button {
          font-size: 0;
          width: unset;
          background-color: #eef0f1;

          i {
            display: block;
            color: #555555;
          }

          &:hover {
            i {
              color: #ffffff;
            }
          }
        }

        .mobile-quick-view {
          display: block;
          margin-left: 5px;
        }
      }

      .quick-view-part {
        display: none;
      }

      &:hover {
        .img-wrapper {
          .front {
            img {
              opacity: 1;
            }
          }
        }

        .ribbon {
          display: block;
        }
      }
    }
  }

  .tools-service {
    .service-block {
      +.service-block {
        margin-top: 0;

        +.service-block {
          margin-top: 20px;
        }
      }
    }
  }

  .tools_slider {
    .home-slider {
      .home {

        .tools-parts,
        .tools-parts1 {
          display: none;
        }

        .slider-contain {
          margin-left: 0;
          justify-content: center !important;
        }
      }
    }
  }

  .game-product {
    .product-box {
      .cart-info {
        right: 5px;

        a {
          i {
            margin: 7px 0;
            padding: 7px;
            font-size: 14px;
          }
        }
      }

      .add-button {
        bottom: 0;
      }

      .img-wrapper {
        padding-bottom: 37px;
      }

      &:hover {
        .cart-info {
          a {
            &:nth-child(1) {
              i {
                animation: none;
              }
            }
          }
        }
      }
    }

    .theme-tab {

      .tab-title,
      .tab-title2 {
        .current {
          a {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .game-banner {
    .banner-timer {
      .banner-text {
        background-color: transparent;
      }
    }
  }

  .service_slide {
    .home-slider {
      .slider-contain {
        // height: 60vh;
      }
    }
  }

  .detail-cannabis {
    .detail_section {
      margin-top: 30px;
      text-align: center;
    }

    .row {
      >div {
        &:first-child {
          .detail_section {
            margin-top: 0;
          }
        }
      }
    }
  }

  .gym-product {
    .product-box {
      .img-wrapper {
        .cart-info {
          display: block;
          height: unset;
          top: 0;
          left: unset;
          right: 0;

          i {
            background: none;
            color: #6f6f6f;
          }

          button,
          a {
            &:hover {
              i {
                color: var(--theme-deafult);
              }
            }
          }
        }
      }

      &:hover {
        .img-wrapper {
          .cart-info {
            button {
              animation: none;
            }

            a {
              &:nth-child(2) i {
                animation: none;
              }

              &:nth-child(3) i {
                animation: none;
              }

              &:nth-child(4) i {
                animation: none;
              }
            }
          }
        }
      }
    }
  }

  .absolute_banner {
    margin-bottom: 0;

    .collection-banner {
      .absolute-contain {
        bottom: 25px;

        h3 {
          font-size: 20px;
        }

        h4 {
          font-size: 16px;
        }
      }
    }
  }

  .box-product {
    .full-box {
      .row {
        >div {
          &:nth-last-child(-n + 3) {
            margin-top: 30px;
          }
        }
      }
    }
  }

  .advertise-section {
    .full-banner {
      .banner-contain {
        h2 {
          font-size: 60px;
          margin-top: -10px;
        }
      }
    }
  }

  footer {
    &.footer-5 {
      .sub-footer {
        >.container {
          border-top: none;
        }
      }

      .dark-layout {
        .footer-title {
          // border-bottom: 1px solid #ffffff;
        }
      }
    }

    &.footer-black {
      .upside {
        .small-section {
          .center-thing {
            display: inline-block;
            width: 100%;
          }
        }
      }

      .footer-title {
        h4 {
          color: #ffffff;
        }
      }

      &.footer-light {
        .subscribe {
          height: auto;
          margin-bottom: 10px;
        }

        .subscribe-form {
          height: auto;
        }
      }

      .below-section {
        padding-top: 85px;
      }
    }
  }

  .lookbook-section {
    .row {
      >div {
        &:first-child {
          .lookbook-img {
            >div {
              &:last-child {
                margin-bottom: 25px;
              }
            }
          }
        }
      }
    }

    .lookbook-img {
      >div {
        &:last-child {
          img {
            margin-top: 0;
          }
        }
      }
    }
  }

  @for $i from 1 through 28 {
    .market-bg#{$i} {
      height: 220px;
    }
  }

  // inner pages //
  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .product-pagination {
    .pagination {
      justify-content: center;
      border-left: 1px solid #dddddd;
    }
  }

  .testimonial {
    background-color: #f7f7f7;

    .testimonial-slider {
      .media {
        .media-body {
          padding: 30px 30px 30px 30px;
        }
      }
    }
  }

  .about-page {
    text-align: center;

    p {
      line-height: 1.5;
    }
  }

  .team {
    h2 {
      font-size: 25px;
    }
  }

  .layout2-logo {
    padding-right: 70px;
  }

  .collection {
    .partition-collection {
      >div {

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
          margin-top: 30px;
        }
      }
    }
  }

  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 15px 18px;
          }
        }
      }

      .product-search-count-bottom {
        justify-content: center;
        padding-right: 0;

        h5 {
          padding: 10px 0;
        }
      }

      .theme-paggination-block {
        nav {
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  .faq-section {
    .theme-accordion {
      .card {
        .card-body {
          p {
            line-height: 1.5 !important;
          }
        }

        .card-header {
          button {
            font-size: 14px !important;
            white-space: normal;
            width: 100%;
            padding: 16px 30px 16px 18px !important;
          }
        }
      }
    }
  }

  .register-page {
    .theme-card {
      padding: 25px;

      .theme-form {
        input {
          margin-bottom: 25px;
          padding: 13px 18px;
        }
      }
    }
  }

  .search-product {
    >div {
      &:nth-last-child(4) {
        margin-top: 30px;
      }
    }
  }

  .cart-section,
  .wishlist-section {
    tbody {
      tr {
        td {
          a {
            justify-content: center;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .cart-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n + 4) {
            display: none;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-last-child(-n + 4) {
            display: none;
          }

          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }

  .wishlist-section {
    .cart-table {
      thead {
        th {
          &:nth-last-child(-n + 3) {
            display: none;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:nth-last-child(-n + 3) {
            display: none;
          }

          .mobile-cart-content {
            display: flex;
          }
        }
      }
    }
  }

  .blog-page {
    .order-sec {
      order: -1;
    }

    .blog-media {
      &:last-child {
        margin-bottom: 30px;
      }
    }

    .blog-sidebar {
      ul {
        li {
          display: block;
        }
      }
    }
  }

  .tab-pane {
    iframe {
      width: 100%;
    }
  }

  .border-box {
    &.tools-grey {
      .product-box {
        .img-wrapper {
          padding-bottom: 0;
        }

        .cart-info {

          a,
          button {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  .product-order {
    .product-order-detail {
      img {
        height: auto;
      }
    }
  }

  .dashboard-section {
    .counter-section {
      .row {
        >div {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .apexcharts-canvas {
      margin: 0 auto;
    }
  }

  .typography_section {
    .typography-box {
      .typo-content {
        &.product-pagination {
          .pagination {
            border-left: none;
          }
        }

        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .product-wrapper-grid {
    &.list-view {
      .product-box {
        display: block;
        border: 1px solid #eee;
        padding: 15px;

        .img-wrapper,
        .img-block {
          margin: 0 auto 15px;
          width: 50%;
          flex: 0 0 50%;
          max-width: 50%;
        }

        .product-detail {
          padding-left: 0;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 577px) {
  header {
    &.header-gym {
      position: relative;
      background-color: #000000;

      .main-menu {
        padding: 0;
        z-index: 9;

        .brand-logo {
          left: 0;
          transform: unset;
        }
      }

      .top-header {
        .header-dropdown {
          li {
            a {
              i {
                padding-right: 0;
              }
            }
          }
        }
      }

      .mobile-fix-option {
        z-index: 2;
      }
    }

    &.header-6 {
      .main-menu {
        .brand-logo {
          position: relative;
          left: 0;
          transform: none;
          margin: 0;
          padding-top: 30px !important;
          padding-bottom: 30px !important;
        }
      }
    }

    &.header-7 {
      position: relative;
      z-index: unset;
      background-color: #333333;

      .main-menu {
        .menu-left {
          .navbar {
            padding: 15px 45px 15px 0 !important;
          }
        }
      }

      .main-nav-center {
        .toggle-nav {
          top: -70px;
        }
      }

      .game-layout {
        background-color: #333333;
      }
    }

    &.green-gradient {
      .top-header {
        .header-dropdown {
          li {
            padding: 0;
          }
        }
      }
    }

    &.header-5 {
      .top-header {
        .header-dropdown {

          .mobile-wishlist,
          .mobile-account {
            img {
              filter: brightness(5);
            }
          }
        }
      }

      .main-menu {
        .brand-logo {
          margin: 0;
          left: 0;
          transform: unset;
        }
      }
    }

    &.left-header {
      .top-header {
        height: 70px;

        .header-dropdown {
          li {
            padding: 0;
          }

          .mobile-wishlist,
          .mobile-account {
            img {
              display: none;
            }

            i {
              display: block;
            }
          }
        }
      }

      .main-menu {
        .menu-left {
          .mobile-logo {
            position: absolute;
            padding: 0 !important;
            transform: unset;
            right: unset;
            left: 15px;
            margin-right: 0;
            top: 16px;
          }

          .navbar {
            position: absolute;
            right: 15px;
            padding: 0 !important;
            top: 22px;
          }
        }
      }
    }

    &.video-header {
      .top-header {
        .header-dropdown {
          li {
            padding: 0;
          }
        }
      }
    }

    &.header-tools {
      .top-header {
        .header-dropdown {
          li {
            padding: 0;
          }
        }
      }
    }
  }

  .portfolio-padding {
    padding-bottom: 15px;
  }

  .portfolio-section {
    .isotopeSelector {
      margin-bottom: 0;
    }
  }

  .portfolio-section {
    .filter-button {
      padding: 0 15px;
      font-size: 16px;
      line-height: 30px;
    }
  }

  .service_slide {
    .home-slider {
      .slider-contain {
        // height: 58vh;
      }
    }
  }

  .portfolio-section {
    &.metro-section {
      .product-box {
        .cart-wrap {
          a {
            i {
              font-size: 18px;
              padding: 7px;
            }
          }

          button {
            i {
              font-size: 18px;
              padding: 7px;
            }
          }
        }
      }
    }
  }

  .about-section {
    .small-section {
      padding-top: 0;
    }
  }

  .main-menu {
    .menu-right {
      .icon-nav {
        .mobile-cart {
          >a {
            img {
              display: none;
            }
          }
        }
      }
    }
  }

  .pet-parallax {
    .full-banner {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .setting-sidebar {
    width: 35px;
    height: 35px;

    i {
      font-size: 19px;
    }
  }

  .dark-light {
    width: 35px;
    height: 35px;
  }

  #fb-root {
    display: none;
  }

  header {
    &.header-tools {
      .top-header {
        .header-dropdown {
          li {
            padding: 0;
          }
        }
      }
    }
  }

  h2 {
    font-size: 24px;
  }

  .dashboard-right {
    .dashboard {
      padding: 20px;

      .page-title {
        h2 {
          font-size: 18px;
        }
      }
    }
  }

  .dashboard {
    .box-head {
      h2 {
        font-size: 18px;
      }
    }
  }

  .top-header {
    .header-dropdown {
      li {
        i {
          padding-right: 0;
        }
      }
    }
  }

  .layout3-menu {
    .main-menu {
      .brand-logo {
        a {
          display: block;
          width: 150px;
        }

        img {
          height: auto;
        }
      }

      .menu-left {
        width: calc(100vw - 32px);

        .main-menu-right {
          .toggle-nav {
            right: 15px;

            .sidebar-name {
              display: none;
            }
          }
        }

        .menu-right {
          .toggle-nav {
            position: absolute;
            z-index: 2;
            right: 15px;
            padding: 0;
            top: 30px;
          }
        }
      }

      >div {
        &:nth-child(2) {
          position: absolute;
          left: 50%;
          right: 50%;
          transform: translate(-50%, 0);
          top: 0;
        }

        &:nth-child(3) {
          height: 0;
        }
      }
    }

    .brand-logo {
      left: 35%;
    }
  }

  .search-overlay {
    >div {
      .closebtn {
        top: 15px;
        right: 30px;
      }
    }
  }

  .btn-solid {
    padding: 7px 15px;
  }

  .product-box,
  .product-wrap {

    .product-detail,
    .product-info {
      .color-variant {
        padding-top: 10px;
      }
    }
  }

  footer {
    margin-bottom: 0px;

    .btn-solid {
      padding: 13px 29px;
    }

    p {
      line-height: 20px;
    }
  }

  .video-section {
    .video-img {
      .play-btn {
        span {
          height: 40px;
          width: 40px;
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  .partition1 {
    margin-bottom: -15px;

    >div {
      margin-bottom: 15px;
    }
  }

  .theme-modal {
    &.modal-dialog {
      .quick-view-modal {
        .product-right {
          margin: 15px 0 10px 0;

          h2 {
            font-size: 18px;
            margin-bottom: 10px;
          }

          h3 {
            font-size: 22px;
            margin-bottom: 10px;
          }

          .color-variant {
            li {
              height: 25px;
              width: 25px;
            }
          }

          .border-product {
            padding-top: 10px;
            padding-bottom: 15px;
          }

          .size-box {
            margin-top: 5px;
            margin-bottom: 5px;
          }

          .product-description {
            .qty-box {
              margin-top: 5px;
            }
          }
        }
      }
    }

    &.cart-modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bg {
              &.addtocart {
                .media {
                  a {
                    img {
                      max-width: 105px;
                      margin-bottom: 10px;
                    }
                  }

                  .media-body {
                    .buttons {
                      a {
                        padding: 4px 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.exit-modal {
      .media {
        align-items: center;

        img {
          width: 25%;
        }

        .media-body {
          h4 {
            font-size: 16px;
            margin-bottom: 0;
          }

          h2 {
            margin-bottom: 2px;
          }

          h5 {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }

      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bg {
              padding: 12px;
            }
          }
        }
      }
    }
  }

  .dark-layout {
    .footer-theme2 {
      .col {
        padding: 0 15px;
      }

      .subscribe-wrapper {
        .subscribe-block {
          padding: 10px 20px 20px 10px;
        }
      }
    }
  }

  .white-layout {
    .small-section {
      .footer-theme2 {
        .col {
          padding: 0 15px;
        }
      }
    }
  }

  .footer-theme2 {
    .col {
      padding: 0 15px;
    }

    .form-p {
      padding: 0;
    }

    &.footer-border {
      padding: 15px 0;
    }

    .subscribe-block {
      padding: 0 75px;
    }

    .footer-link {
      h4 {
        padding-bottom: 10px;
      }

      li {
        padding-right: 20px;
      }
    }

    .footer-link-b {
      h4 {
        padding-bottom: 15px;
      }

      li {
        padding-right: 20px;
      }
    }
  }

  .social-white {
    margin-top: 25px;
  }

  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            font-size: 30px;
            padding: 10px 20px;
          }
        }
      }
    }
  }

  .filter-main-btn {
    span {
      font-size: 14px;
    }
  }

  .category-bg {
    .image-block {
      &:after {
        display: none;
      }
    }

    .contain-block {
      &:after {
        display: none;
      }
    }

    .order-section {
      div {
        order: 1;

        &:nth-child(2) {
          order: 4;
          margin-bottom: 30px;
        }

        &:nth-child(3) {
          order: 3;
        }

        &:nth-child(4) {
          order: 2;
          margin-bottom: 30px;
        }

        &:nth-child(5) {
          order: 5;
        }

        &:nth-child(6) {
          order: 6;
        }
      }
    }

    .contain-block {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .theme-tab {
    .tab-title {
      margin-bottom: 25px;
    }
  }

  .full-banner {
    padding-top: 50px;
    padding-bottom: 50px;

    &.parallax-layout {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .banner-contain {
      background-color: rgba(#ffffff, 0.2);
      padding: 25px;

      .btn-solid,
      .btn-outline {
        padding: 10px 20px;
      }
    }

    &.p-left,
    &.p-right {
      .banner-contain {
        float: none;
      }
    }

    .banner-contain {
      h2 {
        font-size: 65px;
      }

      h3 {
        font-size: 40px;
      }

      h4 {
        font-size: 18px;
        padding-top: 0;
        letter-spacing: 0.3em;
      }
    }

    &.feature-banner {
      .feature-object {
        text-align: left;
        margin-left: 0;
        margin-bottom: 30px;

        li {
          .media {
            .media-body {
              text-align: left;
            }

            img {
              order: -1;
              margin-right: 15px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .home-slider {
    .home {
      // height: 58vh;
    }

    .slider-details {
      top: 18%;
      right: 8%;
      width: 180px !important;
      height: 180px !important;

      h1 {
        font-size: 22px;
        margin: 5px 0;
      }

      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 16px;
      }

      h4 {
        display: none;
      }

      .btn-white {
        padding: 4px 8px;
      }
    }

    .slider-contain {

      // height: 58vh;
      h1 {
        font-size: 30px;
      }
    }

    &:hover {
      .slick-next {
        right: 70px;
      }

      .slick-prev {
        left: 70px;
      }

      .slick-prev,
      .slick-next {
        transform: scale(1.5);
      }
    }

    &.fullpage {
      .slick-dots {
        display: flex;
        bottom: 60px;
        top: unset;
        left: 50%;
        transform: translateX(-50%);
      }

      .home {
        .slider-contain {
          text-align: center;
          justify-content: center;
          padding-left: 0;

          >div {
            background-color: rgba(0, 0, 0, 0.15);
            padding: 30px 50px;
          }

          h1,
          p {
            color: #000000;
          }

          h1 {
            font-size: 22px;
          }

          p {
            max-width: 330px;
            font-size: 12px;
          }

          .btn-solid {
            padding: 10px 15px;
          }
        }
      }
    }
  }

  .background {
    .contain-bg {
      background-color: unset;
      padding-top: 0;
      padding-bottom: 0;
      margin: 10px 0;

      &:hover {
        background-color: unset;

        h4 {
          color: #111111;
        }
      }
    }
  }

  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          flex: 0 0 100%;
          max-width: calc(100% - 10px);

          &:nth-last-child(-n + 2) {
            margin: 0 5px 10px;
          }

          &:nth-last-child(-n + 1) {
            margin: 0 5px 0;
          }

          .product-box2 {
            img {
              height: 150px;
            }

            .media {
              .media-body {
                a {
                  h6 {
                    margin-right: 10px;
                  }
                }

                .color-variant {
                  li {
                    margin-top: 7px;
                    height: 18px;
                    width: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .alert {
    right: 0 !important;
    left: 0;
    max-width: 90% !important;
  }

  .tap-top {
    width: 35px;
    height: 35px;
    padding: 2px;
    font-size: 19px;
  }

  .tap-top.top-cls {
    bottom: 101px !important;
  }

  .filter-main-btn span {
    width: 35px;
    height: 35px;
    background-color: #125453;
  }

  .layout2-logo {
    padding-right: 0;
  }

  .absolute-banner {
    margin-top: 0;

    .absolute-bg {
      box-shadow: none;
      padding-bottom: 0;
    }

    &.banner-padding {
      padding-top: 0;
    }
  }

  .box-layout-body {
    .box-layout {
      margin-top: 0;
    }
  }

  .full-scroll-menu {
    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .layout-8 {
    padding: 0 15px;
  }

  .layout-20 {
    padding: 0;
  }

  .addcart_btm_popup {
    width: 30px;
    height: 32px;

    .fixed_cart {
      font-size: 14px;
    }
  }

  .color-picker {
    a {
      &.handle {
        width: 30px;
        height: 35px;

        i {
          margin-left: 7px;
          font-size: 17px;
        }
      }
    }
  }

  .full-scroll-footer {
    display: none;
  }

  .tools-parallax-product {
    &.full-banner {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }

  .tools-service {
    &.absolute-banner {
      .absolute-bg {
        padding-bottom: 20px;
        box-shadow: 0 0 8px 0 #ddd;
      }
    }

    .service-block {
      +.service-block {
        margin-top: 20px;
      }
    }

    .service-block,
    .service-block1 {
      svg {
        margin-bottom: 7px;
      }
    }
  }

  .modal {
    padding-right: 0 !important;
  }

  .modal-dialog {
    margin: 0.5rem;
  }

  .detail-cannabis {
    .detail_section {
      >div {
        padding: 30px 25px;
      }
    }
  }

  .gym-product {
    .part-cls {
      margin-top: -20px;

      >div {
        &:nth-last-child(-n + 3) {
          .product-box {
            margin-top: 20px;
          }
        }
      }
    }

    & .partition-cls {
      .row {
        margin-left: -10px;
        margin-right: -10px;

        >div {
          padding-left: 10px;
          padding-right: 10px;

          .product-box {
            margin-top: 20px;
          }
        }
      }
    }

    .part-cls {
      margin-left: -10px;
      margin-right: -10px;

      >div {
        padding-left: 10px;
        padding-right: 10px;

        .product-box {
          margin-top: 20px;
        }
      }
    }

    .product-box {
      &:hover {
        transform: unset;
      }
    }
  }

  .tab-left {
    .theme-tab {
      .left-side {
        .left-tab-title {
          margin-bottom: 15px;
        }
      }
    }
  }

  .advertise-section {
    .full-banner {
      padding-top: 50px;
      padding-bottom: 50px;

      .banner-contain {
        padding: 30px 10px;

        h2 {
          font-size: 40px;
          margin-bottom: 0;
          margin-top: -7px;
        }

        h3 {
          font-size: 20px;
          margin-bottom: 3px;
        }

        h4 {
          font-size: 16px;
          letter-spacing: 0.03em;
        }
      }
    }
  }

  .bundle {
    .bundle_img {
      .img-box {
        img {
          max-width: 70px;
        }
      }
    }

    .bundle_detail {
      .theme_checkbox {
        padding: 0;
      }
    }
  }

  // inner pages //
  .error-section {
    padding: 100px 0;

    h1 {
      font-size: 100px;
    }

    h2 {
      margin: 20px 0;
      font-size: 18px;
    }
  }

  .about-page {
    .about-head {
      display: inline-block;
      width: 100%;
      text-align: center;

      .theme-breadcrumb {
        .breadcrumb {
          justify-content: center;
          padding-top: 10px;
          padding-bottom: 0;
        }
      }
    }
  }

  .breadcrumb-section {
    .page-title {
      text-align: center;
      margin: 0 auto;
      vertical-align: middle;
      display: unset;
    }

    .breadcrumb {
      justify-content: center;
      padding: 0;
      line-height: 30px;
    }
  }

  .blog-detail-page {
    .blog-detail {
      .post-social {
        li {
          display: block;

          &+li {
            padding-left: 0;
            margin-left: 0;
            border-left: none;
          }
        }
      }
    }
  }

  .collection {
    .collection-block {
      .collection-content {
        p {
          margin-bottom: 10px;
        }

        h4,
        h3 {
          margin-bottom: 5px;
        }
      }
    }
  }

  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-box {
        margin-top: 30px;
      }
    }

    .product-top-filter {
      .product-filter-content {
        .search-count {
          padding: 10px 20px 10px 20px;

          h5 {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .product-page-per-view,
        .product-page-filter {
          width: 100%;

          select {
            padding: 10px 20px 10px 20px;
          }

          &:before {
            top: 11px;
          }
        }

        .product-page-per-view {
          border-right: 1px solid #dddddd !important;
          border-bottom: 1px solid #dddddd;
        }
      }

      .popup-filter {
        .search-count {
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .product-page-per-view {
          border-bottom: 1px solid #dddddd;
          width: 100%;

          select {
            border-right: none;
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:before {
            top: 14px;
          }
        }

        .product-page-filter {
          width: 100%;
          border-top: none;

          select {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:before {
            left: unset;
            right: 35px !important;
            top: 14px;
          }
        }
      }
    }
  }

  .search-product {
    >div {
      &:nth-last-child(5) {
        margin-top: 30px;
      }
    }
  }

  .cart-section {
    .cart-buttons {
      >div {
        &:last-child {
          padding-right: 15px;
        }
      }

      .btn-solid {
        padding: 7px 8px;
      }
    }
  }

  .wishlist-section {
    .btn-solid {
      padding: 7px 6px;
    }
  }

  .product-box {
    .img-wrapper {
      .lable-block {
        .lable3 {
          font-size: 12px;
          padding: 10px 5px;
        }
      }
    }

    .img-block {
      .lable-wrapper {

        .lable1,
        .lable2 {
          padding: 6px 9px 8px 15px;
          font-size: 12px;
        }
      }
    }
  }

  .tab-product,
  .product-full-tab {
    .tab-content {
      &.nav-material {
        p {
          padding: 15px 0;
        }
      }
    }
  }

  .tab-product {
    .tab-content {
      &.nav-material {
        p {
          line-height: 1.6;
        }
      }
    }
  }

  .slider-right-nav {
    .slick-slide {
      &:first-child {
        >div {
          margin-top: 15px;
        }
      }
    }
  }

  .collection-wrapper {
    .order-up {
      order: -1;
    }
  }

  .lookbook {
    .lookbook-block {
      .lookbook-dot {
        .dot-showbox {
          width: 90px;
          top: -70px;

          .dot-info {
            h5 {
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .layout7-product {
    .product-box {
      .details-product {
        padding: 5px;
      }

      &:hover {
        .details-product {
          animation: none;
        }
      }
    }
  }

  .checkout-page {
    .checkout-form {
      .checkout-details {
        padding: 15px;
      }
    }
  }

  .padding-cls {
    padding-left: 15px;
    padding-right: 15px;
  }

  .success-text {
    i {
      font-size: 40px;
    }

    p {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    h2 {
      margin-bottom: 10px;
    }
  }

  .order-success-sec {
    >div {
      margin-top: 15px;
    }
  }

  .delivery-sec {
    padding: 15px;
    margin-top: 15px;

    h3,
    h2 {
      font-size: 20px;
    }
  }

  .product-order {
    h3 {
      font-size: 20px;
    }

    .product-order-detail {
      .order_detail {

        h4,
        h5 {
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }

    .total-sec {
      ul {
        li {
          font-size: 16px;
        }
      }
    }

    .final-total {
      h3 {
        font-size: 16px;
      }
    }
  }

  .beauty-about {
    .service {
      .service-block1 {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .main-menu {
    .menu-left {
      .navbar {
        i {
          font-size: 24px;
        }
      }
    }
  }

  .bg-title {
    .theme-tab {
      .bg-title-part {
        display: block;
        text-align: center;

        .tab-title {
          margin-left: unset;
          text-align: center;

          li {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .service_slide {
    .home-slider {
      .slider-contain {
        // height: 58vh;
      }
    }
  }

  .vendor-profile {
    margin-top: -50px;
  }

  .vendor-cover {
    .bg-size {
      height: 250px;
    }
  }

  .start-selling {
    form {
      .row {
        >div {
          &:nth-child(2) {
            margin-top: 15px;
          }
        }
      }
    }
  }

  .become-vendor {
    .step-bg {
      .step-box {
        .steps {
          margin: 0 auto 15px auto;
        }
      }
    }
  }

  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            &:last-child {
              margin-top: 0;
            }
          }

          .btn-solid {
            &.btn-sm {
              padding: 3px 12px;
            }
          }
        }
      }
    }
  }

  .theme-card {
    &.card-border {
      h5 {
        padding-left: 30px;
      }
    }
  }

  .banner-furniture {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .masonry-grid-col {
    .isotopeSelector {
      width: 100% !important;
    }
  }

  .leftImage {
    .order-down {
      .slick-track {
        display: flex;
      }
    }
  }

  .thumbnail-col {
    .slick-vertical {
      .slick-track {
        display: flex;
        margin: 0 10px;
      }
    }
  }
}

@media (max-width: 480px) {
  .setting-box {
    width: 300px;
  }

  .theme-modal.modal-dialog {
    .modal-content {
      .modal-body {
        .modal-bg {
          .btn-close {
            right: 20px;
            top: 20px;
          }
        }
      }
    }
  }

  .Toastify__toast-container {
    width: 90vw;
    margin: 0 auto;
  }

  .pet-parallax {
    .full-banner {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .quick-view-modal {
    .product-buttons {
      .btn {
        margin-left: 10px !important;
        margin-right: 10px !important;
        margin-bottom: 10px;
      }
    }
  }

  .title1 {
    .title-inner1 {
      margin-bottom: 20px;
    }
  }

  .title2 {
    .title-inner2 {
      margin-bottom: 20px;
    }
  }

  .title3 {
    .line {
      margin-bottom: 20px;
    }
  }

  .title4 {
    .line {
      margin-bottom: 20px;
    }
  }

  .title-borderless {
    margin-bottom: 20px;
  }

  .subscribe-form {
    &.classic-form {
      .form-control {
        margin-left: 0;
      }
    }
  }

  .product-box {
    .img-block {
      min-height: 290px;
    }
  }

  .theme-tab {
    .tab-title {
      li {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .tab-title2 {
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 400;

      li {
        padding-left: 15px;
        padding-right: 15px;
      }

      &:after {
        display: none;
      }
    }
  }

  .blog-details {
    padding-left: 0;
    padding-right: 0;
  }

  .full-banner {
    padding-top: 40px;
    padding-bottom: 40px;

    &.parallax-layout {
      padding-top: 4px;
      padding-bottom: 40px;
    }

    .banner-contain {
      h2 {
        font-size: 60px;
      }

      h4 {
        font-size: 18px;
      }
    }

    .santa-img {
      bottom: -20px;
      left: 10px;

      img {
        width: 76px;
      }
    }
  }

  .collection-banner {
    .contain-banner {
      padding-left: 30px;
      padding-right: 30px;

      h2 {
        font-size: 24px;
      }
    }
  }

  .home-slider {
    .home {
      // height: 55vh;
    }

    .slider-contain {

      // height: 55vh;
      h1 {
        font-size: 28px;
      }

      h4 {
        letter-spacing: 0.1em;
      }

      .btn-solid,
      .btn-outline {
        margin-top: 5px;
      }
    }

    .slider-details {
      width: 150px !important;
      height: 150px !important;

      h1 {
        font-size: 20px;
      }

      h2 {
        font-size: 16px;
      }

      h3 {
        font-size: 14px;
      }
    }

    &:hover {

      .slick-prev,
      .slick-next {
        transform: scale(1.2);
      }

      .slick-next {
        right: 60px;
      }

      .slick-prev {
        left: 60px;
      }
    }
  }

  .white-layout {
    .footer-theme2 {
      .footer-block {
        .footer-container {
          max-width: 448px;
        }
      }
    }
  }

  .footer-theme2 {
    &.section-light {
      .footer-block {
        .subscribe-white {
          padding: 30px 30px;

          .form-control {
            letter-spacing: 3px;
          }
        }
      }
    }
  }

  .theme-modal {
    &.modal-dialog {
      .modal-content {
        .modal-body {
          .modal-bg {
            padding: 35px;

            .offer-content {
              img {
                margin-bottom: 10px;
              }

              h2 {
                margin-bottom: 15px;
              }
            }

            .age-content {
              form {
                .form-control {
                  height: 40px;
                  padding: 0;
                }

                .btn-solid,
                .btn-outline {
                  padding: 8px 15px;
                }
              }
            }
          }
        }
      }
    }

    &.cart-modal {
      .modal-dialog {
        .modal-content {
          .modal-body {
            .modal-bg {
              &.addtocart {
                .media {
                  display: block;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            .media {
              .media-body {
                h4 {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-bg,
  .bg-block {
    padding: 35px 25px 30px 25px;
  }

  .theme-card {
    .offer-slider {
      img {
        max-width: 130px;
        height: auto;
      }
    }
  }

  .service_slide {
    .home-slider {
      .slider-contain {
        // height: 55vh;
      }
    }
  }

  .advertise-section {
    .full-banner {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .gym-product {
    &.partition-cls {
      .row {
        margin-left: -10px;
        margin-right: -10px;

        >div {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  // inner pages //
  .about-page {
    p {
      line-height: 1.5;
    }
  }

  .testimonial {
    .testimonial-slider {
      .media {
        display: inline-block;

        img {
          margin: 0 auto;
          height: 100px;
          width: 100px;
        }

        .media-body {
          padding: 0;
          text-align: center;
        }
      }
    }
  }

  .blog-detail-page {
    .blog-contact {
      h2 {
        font-size: 30px;
        margin-bottom: 25px;
      }

      .theme-form {

        input,
        textarea {
          padding: 12px 20px;
          margin-bottom: 20px;
        }
      }
    }

    .blog-detail {
      h3 {
        font-size: 14px;
        margin-bottom: 15px;
      }

      img {
        margin-bottom: 25px;
      }
    }

    .blog-advance {
      ul {
        line-height: 1.5;
        font-size: 14px;
      }

      img {
        margin-bottom: 20px;
        margin-top: 10px;
      }

      p {
        line-height: 1.5;
      }
    }

    .comment-section {
      li {
        padding-top: 30px;
        padding-bottom: 30px;

        p {
          line-height: 1.5;
        }

        h6 {
          margin-top: 10px;
          margin-bottom: 10px;

          span {
            display: inherit;
            margin-left: 0;
          }
        }
      }

      .media {
        display: inline-block;
        text-align: center;
      }
    }
  }

  .faq-section {
    .theme-accordion {
      .card {
        .card-body {
          p {
            line-height: 1.5 !important;
          }
        }

        .card-header {
          button {
            line-height: 1.3;
            padding: 12px 35px 12px 12px !important;
          }
        }
      }
    }

    .accordion {
      &.theme-accordion {
        .card {
          .card-header {
            button {
              &:before {
                top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .pwd-page {
    padding: 70px 0;

    h2 {
      font-size: 25px;
      margin-bottom: 15px;
    }
  }

  .login-page {
    .authentication-right {
      h6 {
        margin-bottom: 10px;
      }
    }

    .theme-card {
      padding: 25px;
    }
  }

  .register-page {
    .theme-card {
      padding: 18px;

      .theme-form {
        input {
          margin-bottom: 20px;
          padding: 12px 15px;
        }
      }
    }
  }

  .search-block {
    .btn-solid {
      padding: 10px 15px;
    }
  }

  .contact-page {
    .map {
      iframe {
        height: 275px;
      }
    }

    .theme-form {
      input {
        padding: 12px 15px;
        margin-bottom: 18px;
      }
    }
  }

  .blog-page {
    .blog-media {
      .blog-right {
        h6 {
          margin-bottom: 0;
        }

        p {
          line-height: 1.3;
        }

        h4 {
          margin-bottom: 5px;
        }

        ul {
          margin-bottom: 5px;

          li {
            display: block;

            &+li {
              padding-left: 0;
              margin-left: 0;
              border-left: none;
            }
          }
        }
      }
    }
  }

  .account-sidebar {
    width: 45%;
  }

  .success-text {
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .delivery-sec {

    h3,
    h2 {
      font-size: 18px;
    }
  }

  .dashboard-section {
    .dashboard-box {
      .dashboard-detail {
        ul {
          li {
            .details {
              display: block;

              .left {
                width: auto;

                h6 {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }

  .typography_section {
    .typography-box {
      .typo-content {
        &.typo-buttons {
          a {
            margin: 5px 0;
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .footer-light {
    .subscribe-form {
      .form-control {
        width: 185px;
        padding: 7px 13px;
      }
    }
  }

  h2 {
    font-size: 22px;
  }

  .subscribe {
    h4 {
      display: block;
    }
  }

  footer {
    .btn-solid {
      padding: 7px 8px;
    }

    p {
      line-height: 25px;
    }

    &.footer-black {
      .subscribe-form {
        .form-control {
          height: 40px;
          width: 160px;
        }
      }
    }
  }

  .blog-details {
    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }

  .product-box,
  .product-wrap {
    .cart-detail {
      i {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;
      }
    }

    .cart-info,
    .cart-wrap {
      i {
        padding: 5px;
        font-size: 13px;
      }
    }

    .img-wrapper {
      .lable-block {
        .lable4 {
          font-size: 10px;
        }
      }

      .cart-box {
        i {
          font-size: 16px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }

    .product-detail,
    .product-info {
      .rating {
        margin-top: 5px;
      }

      h6 {
        font-size: 14px;
      }

      h4 {
        font-size: 15px;
      }

      .color-variant {
        padding-top: 3px;

        li {
          height: 14px;
          width: 14px;
          margin-right: 3px;
        }
      }
    }

    .cart-info,
    .cart-wrap {
      &.cart-wrap {
        i {
          padding: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .addtocart_count {
    .product-box {
      .cart-info {
        a {
          i {
            margin: 4px 0;
            padding: 5px;
            font-size: 14px;
          }
        }
      }

      .add-button {
        font-size: 14px;
      }
    }

    .full-box {
      .center-slider {
        .offer-slider {
          .product-box {
            .cart-info {
              display: none;
            }

            .addtocart_btn {
              display: none;
            }

            .product-detail {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .timer {
    span {
      .padding-l {
        padding-left: 5px;
      }
    }
  }

  .about-text {
    p {
      line-height: 22px;
    }
  }

  .footer-theme {
    .sub-title {
      h4 {
        margin-bottom: 10px;
        font-size: 16px !important;
      }

      .contact-list {
        li {
          line-height: 25px;
        }
      }
    }
  }

  .footer-theme2 {
    p {
      line-height: 25px;
    }
  }

  .breadcrumb-section {
    padding: 15px 0;
  }

  .white-layout {
    .footer-theme2 {
      .footer-block {
        .footer-container {
          max-width: 388px;
        }

        .subscribe-white {

          .btn-solid,
          .btn-outline {
            padding: 13px 8px;
          }
        }
      }
    }
  }

  .dark-layout {
    .footer-theme2 {
      .subscribe-wrapper {
        .subscribe-block {

          .btn-solid,
          .btn-outline {
            padding: 13px 8px;
          }
        }
      }
    }
  }

  .footer-theme2 {
    .subscribe-block {
      .form-control {
        letter-spacing: 3px;
      }
    }
  }

  .subscribe-form {
    display: inline-block;

    .form-group {
      margin-bottom: 10px;
    }
  }

  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            font-size: 25px;
          }
        }
      }
    }
  }

  .no-slider {
    margin-left: -10px !important;
    margin-right: -10px !important;

    .product-box {
      margin: 0 10px 20px !important;
      max-width: calc(50% - 20px);

      &:nth-last-child(-n + 2) {
        margin: 0 10px 0 !important;
      }
    }

    &.five-product {
      .product-box {
        margin: 0 10px 20px !important;
        max-width: calc(50% - 20px);

        &:nth-last-child(-n + 5) {
          margin: 0 10px 0 !important;
        }
      }
    }
  }

  .j-box {
    .product-box {
      .product-detail {
        padding-left: 8px;
        padding-bottom: 8px;

        .rating {
          padding-top: 8px;
        }
      }
    }
  }

  .full-banner {
    .banner-contain {
      h2 {
        font-size: 55px;
      }

      h3 {
        font-size: 36px;
      }
    }
  }

  .home-slider {
    .slider-contain {
      h1 {
        font-size: 25px;
        margin-top: 10px;
      }

      h4 {
        letter-spacing: 0.01em;
      }

      .btn-solid,
      .btn-outline {
        margin-top: 10px;
        padding: 5px 18px;
      }
    }

    .slider-details {
      width: 120px !important;
      height: 120px !important;
      top: 24%;

      h1 {
        font-size: 18px;
      }

      h2 {
        font-size: 14px;
      }

      h3 {
        font-size: 12px;
      }

      .btn-white {
        padding: 0px 2px;
        margin-top: 0;
        font-size: 10px;
      }
    }

    &:hover {

      .slick-prev,
      .slick-next {
        transform: scale(1.5);
      }

      .slick-next {
        right: 50px;
      }

      .slick-prev {
        left: 50px;
      }
    }
  }

  .theme-card {
    &.center-align {
      display: unset;
      height: unset;
      align-items: unset;
    }

    &.card-border {
      .offer-slider {
        img {
          padding: 0 15px 0 15px;
        }
      }
    }
  }

  .full-box {
    .center-slider {
      .offer-slider {
        .product-box {
          display: flex;
          border-bottom: 1px solid #f1f5f4;
          border-top: 1px solid #f1f5f4;

          .img-wrapper {
            img {
              height: 130px;
              padding-left: 0;
              padding-right: 0;
            }
          }

          .product-info {
            text-align: left;

            h6 {
              padding-top: 5px;
            }

            .color-variant {
              padding-top: 2px;

              li {
                height: 15px;
                width: 15px;
                padding-right: 3px;
              }
            }
          }

          .product-detail {
            padding-left: 10px;
            display: flex;
            align-items: center;

            h4 {
              font-size: 20px;
            }

            .color-variant {
              padding-top: 0;
            }
          }

          &:hover {
            .product-info {
              .btn-outline {
                display: none;
              }

              .color-variant {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .theme-card {
      .offer-slider {
        .sec-1 {
          display: inline;

          .product-box2 {
            border-bottom: 1px solid #f1f5f4;

            &:last-child {
              border-bottom: none;
            }

            +.product-box2 {
              border-left: none;
            }
          }
        }
      }
    }
  }

  .center-slider {
    .offer-slider {
      .product-box {
        .product-detail {
          text-align: left !important;

          .rating {
            i {
              padding-right: 3px;
            }
          }

          h4 {
            padding-bottom: 0.5rem;
          }

          .color-variant {
            li {
              height: 18px;
              width: 18px;
              padding-right: 3px;
            }
          }
        }

        .img-wrapper {
          .cart-box {
            display: none;
          }
        }
      }
    }
  }

  .product-box,
  .product-wrap {
    .cart-detail {
      i {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;
      }
    }

    .cart-info,
    .cart-wrap {
      i {
        padding: 5px;
        font-size: 13px;
      }
    }

    .img-wrapper {
      .lable-block {
        .lable4 {
          font-size: 10px;
        }
      }

      .cart-box {
        i {
          font-size: 16px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }

    .product-detail,
    .product-info {
      .rating {
        margin-top: 5px;
      }

      h6 {
        font-size: 14px;
      }

      h4 {
        font-size: 15px;
      }

      .color-variant {
        padding-top: 3px;

        li {
          height: 14px;
          width: 14px;
          margin-right: 3px;
        }
      }
    }

    .cart-info,
    .cart-wrap {
      &.cart-wrap {
        i {
          padding: 5px;
          font-size: 13px;
        }
      }
    }
  }

  .product-box {
    .img-wrapper {
      .lable-block {
        .lable3 {
          font-size: 10px;
          padding: 10px 5px;
        }
      }

      .cart-box {
        padding: 8px 8px;
        bottom: 10px;
      }
    }

    .img-block {
      .lable-wrapper {
        top: 20px;

        .lable1,
        .lable2 {
          padding: 5px 5px 5px 8px;
          font-size: 12px;
        }
      }
    }

    .cart-info {
      bottom: 10px;
    }
  }

  .team {
    h6 {
      line-height: 15px;
    }
  }

  .theme-modal {
    &.modal-dialog {
      .modal-content {
        .modal-body {
          .modal-bg {
            padding: 30px;

            .offer-content {
              h2 {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }

  .login-page {
    .authentication-right {
      p {
        line-height: 1.5;
      }
    }

    .theme-card {
      .theme-form {
        input {
          padding: 15px 20px;
          margin-bottom: 25px;
        }
      }
    }
  }

  .tab-product {
    .nav-material {
      &.nav-tabs {
        display: block;

        .nav-item {
          width: 100%;

          .nav-link {
            padding: 10px 20px;
            width: 100%;
          }
        }
      }
    }
  }

  .vertical-tab {

    &.tab-product,
    .product-full-tab {
      .nav-material {
        &.nav-tabs {
          .nav-item {
            .nav-link {
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }

  .product-right {
    .product-buttons {

      .btn-solid,
      .btn-outline {
        padding: 7px 13px;
      }
    }

    &.product-form-box {
      .timer {
        padding-left: 29px;

        span {
          width: 45px;
        }
      }
    }

    .timer {
      padding-left: 35px;

      span {
        width: 45px;
      }
    }

    .product-icon {
      .product-social {
        margin-top: 0;

        li {
          padding-right: 5px;

          a {
            i {
              font-size: 14px;
            }
          }
        }
      }

      .wishlist-btn {
        i {
          font-size: 14px;
          padding-left: 10px;
          margin-left: 5px;
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  .tab-product {
    .theme-form {

      input,
      textarea {
        font-size: 12px;
        padding: 8px 15px;
      }
    }
  }

  .order-box {
    .sub-total {
      .shipping {
        width: unset;
        float: unset;
        display: flex;

        .shopping-option {
          &:last-child {
            padding-left: 20px;
          }
        }
      }
    }
  }

  .checkout-page {
    .checkout-form {
      .form-group {
        margin-bottom: 15px;
      }

      input {

        &[type="text"],
        &[type="email"],
        &[type="password"],
        &[type="tel"],
        &[type="number"],
        &[type="url"] {
          height: 40px;
        }
      }

      select,
      textarea {
        height: 40px;
      }
    }
  }

  .product-m {
    .slick-list {
      margin-left: -10px;
      margin-right: -10px;
    }

    .slick-slide {
      >div {
        margin: 0 10px;
      }
    }
  }

  .game-product {
    .product-box {
      .add-button {
        font-size: 14px;
        bottom: 6px;
      }
    }
  }

  .service_slide {
    .service-home {
      .service-block1 {
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .tools-grey {
    .product-box {
      .product-info {
        padding: 8px;

        .rating {
          padding-top: 0;
        }

        h4 {
          font-size: 13px;
        }
      }
    }
  }

  .absolute_banner {
    .collection-banner {
      .absolute-contain {
        padding: 10px;
        bottom: 10px;
      }
    }
  }

  .absolute-product {
    .product-box {
      .product-detail {
        .cart-bottom {
          i {
            padding-right: 4px;
            padding-left: 4px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .grid-products {
    margin-left: -10px;
    margin-right: -10px;

    .product-box {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .grid-products {
    margin-left: -10px;
    margin-right: -10px;

    .product-box {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @for $i from 1 through 28 {
    .market-bg#{$i} {
      height: 180px;
    }
  }

  // inner pages
  .collection-product-wrapper {
    .product-wrapper-grid {
      .product-box {
        margin-top: 20px;
      }
    }
  }

  .product-pagination {
    margin: 30px 0;
  }
}

@media (max-width: 360px) {
  .modal {
    .modal-body {
      .product-buttons {
        display: inline-block;

        .btn {
          &:first-child {
            display: block;
            margin-bottom: 5px;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .btn-solid,
  .btn-outline {
    padding: 10px 15px;
  }

  .pet-parallax {
    .full-banner {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  h2 {
    font-size: 25px;
  }

  .category-button {
    .col {
      flex-basis: unset;
    }
  }

  .footer-theme2 {
    .subscribe-block {
      padding: 0 30px;
    }
  }

  .white-layout {
    .small-section {
      .footer-theme2 {
        .col {
          padding: 0 15px;
        }
      }
    }

    .footer-theme2 {
      .footer-block {
        .footer-container {
          max-width: 330px;
        }
      }
    }
  }

  .footer-theme2 {
    &.section-light {
      .footer-block {
        .subscribe-white {
          padding: 20px 20px;
        }
      }
    }
  }

  .dark-layout {
    .footer-theme2 {
      .subscribe-wrapper {
        .subscribe-block {
          .form-group {
            .form-control {
              border: none;
            }
          }
        }
      }
    }
  }

  .footer-end {
    p {
      font-size: 13px;
    }
  }

  .category-border {
    div {
      .category-banner {
        .category-box {
          h2 {
            padding: 7px 15px;
          }
        }
      }
    }
  }

  .full-banner {
    padding-top: 30px;
    padding-bottom: 30px;

    &.parallax-layout {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .banner-contain {
      h2 {
        font-size: 55px;
      }

      h3 {
        font-size: 30px;
      }
    }
  }

  .home-slider {
    .home {
      // height: 50vh;
    }

    .slider-details {
      top: 20%;

      h1 {
        font-size: 16px;
      }

      h2 {
        font-size: 13px;
      }
    }

    .slider-contain {

      // height: 50vh;
      h1 {
        font-size: 25px;
      }

      .btn-solid,
      .btn-outline {
        margin-top: 8px;
        padding: 5px 15px;
      }
    }

    &:hover {

      .slick-prev,
      .slick-next {
        transform: scale(1.1);
      }

      .slick-next {
        right: 40px;
      }

      .slick-prev {
        left: 40px;
      }
    }
  }

  .layout-7 {
    .home {
      .slider-contain {
        // height: 50vh;
      }
    }
  }

  .theme-tab {
    .tab-content {
      .product-tab {
        .tab-box {
          .product-box2 {
            .media {
              .media-body {
                a {
                  h6 {
                    font-size: 13px;
                  }
                }

                .color-variant {
                  li {
                    height: 12px;
                    width: 12px;
                  }
                }
              }
            }

            img {
              height: 115px;
            }
          }
        }
      }
    }
  }

  .blog-detail-page {
    .blog-detail {
      p {
        line-height: 1.5;
      }
    }

    .blog-contact {
      h2 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  .about-page {
    p {
      line-height: 1.2;
    }
  }

  .theme-modal {
    &.modal-dialog {
      .modal-content {
        .modal-body {
          padding: 0;

          .modal-bg {
            padding: 20px;

            .close {
              right: 35px;
            }

            .offer-content {
              form {
                .form-group {
                  .form-control {
                    padding-top: 10px;
                    padding-bottom: 10px;
                  }
                }
              }

              h2 {
                font-size: 20px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }

  .service_slide {
    .home-slider {
      .slider-contain {
        // height: 50vh;
      }
    }
  }

  .collection-product-wrapper {
    .product-pagination {
      .pagination {
        .page-item {
          a {
            padding: 15px 13px;
          }
        }
      }

      .product-search-count-bottom {
        padding-left: 10px;

        h5 {
          line-height: 15px;
        }
      }
    }

    .product-top-filter {
      .popup-filter {
        .sidebar-popup {
          width: 80%;
        }
      }
    }
  }

  .login-page {
    .theme-card {
      padding: 18px;
    }
  }

  .search-block {
    .btn-solid {
      padding: 10px 5px;
    }

    .form-header {
      .input-group {
        input {
          padding: 10px 12px;
        }
      }
    }
  }

  .contact-page {
    .map {
      iframe {
        height: 200px;
      }
    }
  }

  .cart-section,
  .wishlist-section {
    tbody {
      tr {
        td {
          min-width: 115px;

          .mobile-cart-content {
            .col-xs-3 {
              margin-left: 5px;
              margin-right: 5px;
            }
          }

          a {
            img {
              height: 80px;
            }
          }
        }
      }
    }

    tfoot {
      tr {
        td {
          padding-right: 27px;

          h2 {
            font-size: 21px;
          }
        }
      }
    }
  }

  .wishlist-section {
    .wishlist-buttons {
      &:last-child {
        a {
          margin-left: 2px;
        }
      }
    }
  }

  .product-buttons {

    .btn-solid,
    .btn-outline {
      padding: 7px 16px;
    }
  }

  .product-description {
    .qty-box {
      .input-group {
        width: 100%;
      }
    }
  }

  .single-product-tables {
    &.detail-section {
      table {
        width: 70%;
      }
    }
  }

  .product-right {
    .timer {
      padding-left: 25px;

      span {
        .padding-l {
          padding-left: 10px;
        }
      }
    }
  }

  .product-form-box {
    .timer {
      span {
        width: 47px;

        .padding-l {
          padding-left: 9px;
        }
      }
    }

    .product-buttons {

      .btn-solid,
      .btn-outline {
        padding: 5px 5px;
      }
    }
  }

  .product-form-box,
  .border-product {
    .timer {
      span {
        width: 45px;

        .padding-l {
          padding-right: 0;
        }
      }
    }
  }

  .tools-parallax-product {
    .tools-description {
      .tools-form {
        .search-box {
          width: 92%;
        }
      }
    }
  }

  .advertise-section {
    .full-banner {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .order-box {
    .sub-total {
      .shopping-option {
        label {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .home-slider {
    .slider-details {
      top: 16%;
    }
  }

  .footer-theme {
    .footer-logo {
      margin-bottom: 20px;
    }
  }

  .footer-theme2 {
    .col {
      padding: 10px 5px;
    }
  }

  .white-layout {
    .footer-theme2 {
      .col {
        padding: 0 15px;
      }

      .footer-block {
        .footer-container {
          max-width: 290px;
        }
      }
    }
  }

  .dark-layout {
    .footer-theme2 {
      .subscribe-wrapper {
        .subscribe-block {
          .form-group {
            .form-control {
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }

  .footer-theme2 {
    &.section-light {
      .footer-block {
        .subscribe-white {
          .form-control {
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .collection-banner {
    .contain-banner {
      &.banner-3 {
        padding-left: 25px;
        padding-right: 25px;

        h2 {
          font-size: 15px;
        }
      }

      &.banner-4 {
        padding-left: 35px;
        padding-right: 35px;

        h2 {
          font-size: 15px;
        }
      }
    }
  }
}


.notification-white {
  width: 6px;
  height: 6px;
  background-color: #f99d1b;
  border-radius: 50%;
  position: absolute;
  right: 6px;
  top: 1px;
}

.message-white {
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: 6px;
  top: 1px;
}

.dropdown button:hover {
  color: #000 !important;
}


// media css


// 1199 css

@media screen and (max-width:1199px) {
  .mentor_f .select__value-container {
    padding: 10px 15px !important;
  }
  .detailk_consudy .slick-prev{
    left: 86% !important;
  }

  .custome_posyith{
    position: static !important;
  }
}

// 991 css

@media screen and (max-width:991px){
  .detail_poject{
    gap: 10px;
  }
}

// 767

@media screen and (max-width:767px) {
  .bg_careermentor {
    height: 260px !important;
  }
}


// 575 css

@media screen and (max-width:575px) {
  .bg_careermentor {
    height: auto !important;
  }
}

// 450 css

@media screen and (max-width:450px) {
  .footer-logo img {
    width: 200px;
  }

  .slide_brand img {
    height: 120px;
    width: 120px !important;
    padding: 10px;
  }
}


// 400 css

@media screen and (max-width:400px) {
  .slide_brand img {
    height: 100px;
    width: 100px !important;
  }
}